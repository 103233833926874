import React from 'react';

const DashboardHeader = ( {title} ) => {

  return (
    <div className="header">
      <h1>{title}</h1>
    </div>
  );
};

export default DashboardHeader;