import getDefaultRequest from "./API.js";

export const Auth = () => {

  const login = async (email, password) => {
    try {
      return await getDefaultRequest().post('auth/signin', { email, password });
    } catch (error) {
      return error.response;
    }
  }

  const PreRegister = async (Name, Email, Password) => {
    try {
      return await getDefaultRequest().post('auth/preregister', { Name, Email, Password });
    } catch (error) {
      return error.response;
    }
  }

  const ForgotPassword = async (Email) => {
    try {
      return await getDefaultRequest().post('auth/forgotpw', { email: Email });
    } catch (error) {
      return error.response;
    }
  }

  const ResetPassword = async (Email, Password, PasswordConfirmation, Token) => {
    try {
      return await getDefaultRequest().post('auth/resetpw', {
        email: Email,
        password: Password,
        password_confirmation: PasswordConfirmation,
        token: Token,
      });
    } catch (error) {
      return error.response;
    }
  }

  return { login, PreRegister, ForgotPassword, ResetPassword };

};