import React from 'react';
import Routes from './routes/Routes';
import SideMenu from './dashboard-menu/SideMenu';
import { AuthContext, useAuthState } from './context/AuthContext';
import './App.css';

const App = () => {

  const authState = useAuthState();

  return (
    <AuthContext.Provider value={authState}>
      <div className="dashboard-container">
        {authState.isUserLoggedIn && <SideMenu />}
        <Routes />
      </div>
    </AuthContext.Provider>
  );
};

export default App;
