import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { listCountries, listselection, listStates, listClientSecret, getAccountContactDetails, updateAccountContactDetail, searchCompanyByGTIN, claimCompany, confirmTermsConditions, updateAccountReview } from '../../api/SignUp';

export interface IOrderState {
  country: Array<any>;
  state: Array<any>;
  total: number;
  is_more: boolean;
  loader: boolean;
  error: object;
  form: any;
  wizardIndex: number;
  clientSecret: string;
  isContactValidate: boolean;
  isShippingValidate: boolean;
  isPaymentValidate: boolean;
  numberOfEmployees:Array<any>;
  annualTurnover:Array<any>;
  industries:Array<any>;
}

export const initialState: IOrderState = {
  country: [],
  state: [],
  form: {},
  total: 0,
  is_more: false,
  loader: false,
  error: {},
  wizardIndex: 0,
  clientSecret: "",
  isContactValidate: false,
  isShippingValidate: false,
  isPaymentValidate: false,
  numberOfEmployees:[],
  annualTurnover:[],
  industries:[],
};

export const getStartedSlice = createSlice({
  name: "getStarted",
  initialState,
  reducers: {
    setCountry: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.country = payload;
    },
    setState: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.state = payload;
    },
    setNumberOfEmployee: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.numberOfEmployees = payload;
    },
    setAnnualTurnover: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.annualTurnover = payload;
    },
    setIndustries:(state: IOrderState, { payload }: PayloadAction<any>) => {
      state.industries = payload;
    },
    setAccountDetail: (state: IOrderState, { payload }: PayloadAction<any>) => {
      const form = {
        ...state.form,
        company:payload.company,
        first_name:payload.user?.NameFirst,
        last_name:payload.user?.NameSurname,
        prefered_name:payload.user?.NamePreferred,
        position:payload.user?.Position,
        direct_landline_number:payload.user?.PhoneDirect,
        mobile_number:payload.user?.PhoneMobile,
        company_name:payload.signup?.CompanyName,
        trading_name:payload.signup?.TradingName,
        company_abn:payload.signup?.CompanyABN,
        number_of_employees:payload.signup?.EmployeeCount,
        annual_turnover:payload.signup?.CompanyTurnover,
        industry:payload.company?.Industry,
        other:payload.company?.IndustryOther,
        company_phone_number:payload.signup?.PhoneCompany,
        website:payload.signup?.Website,
        company_address_1:payload.signup?.Address1,
        company_address_2:payload.signup?.Address2,
        city:payload.signup?.City,
        state:payload.signup?.State,
        postcode:payload.signup?.Postcode,
        country_id:(payload.signup?.FK_Country || state.form?.country_id),
      }
      state.form = form;
    },
    setCompany: (state: IOrderState, { payload }: PayloadAction<any>) => {
      return (state = {
        ...state,
        form: {
          ...state.form,
          company: payload,
        },
      });
    },
    setClientSecret: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.clientSecret = payload.client_secret;
    },
    orderSubmit: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.form = {};
    },
    setIndex: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.wizardIndex = payload;
    },
    addForm: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.form = payload;
    },
    setError: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setLoader: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.loader = payload;
    },
    contactValidate: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.isContactValidate = payload;
    },
    shippingValidate: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.isShippingValidate = payload;
    },
    paymentValidate: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.isPaymentValidate = payload;
    },
  },
});

export const submitOrder = (query: object) => async (dispatch: any) => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  const json = await confirmTermsConditions();
  if (json.status == 204) {
    dispatch(orderSubmit(json.data));
  } else if (json.status == 400) {
    dispatch(setError(json.data));
  }
  dispatch(setLoader(false));
  return json;
};

export const submitAccountDetails = (query: object) => async (dispatch: any) => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  const json = await updateAccountContactDetail(query);
  if (json.status == 200) {
  } else if (json.status == 400) {
    dispatch(setError(json.data));
  }
  dispatch(setLoader(false));
  return json;
};

export const submitAccountReview = (query: object) => async (dispatch: any) => {
  const json = await updateAccountReview();
  return json;
};

export const submitClaimCompany = (query: object) => async (dispatch: any) => {
  const json = await claimCompany(query);
  return json;
};



export const fetchAccountDetails = () => async (dispatch: any) => {
  const json = await getAccountContactDetails();
  if (json.status == 200) {
    await dispatch(setAccountDetail(json.data));
  }
  return json;
};

export const fetchCountry = () => async (dispatch: any) => {
  const json = await listCountries();
  if (json.status == 200) {
    dispatch(setCountry(json.data));
  }
  return json;
};

export const fetchState = (query: object) => async (dispatch: any) => {
  const json = await listStates(query);
  if (json.status == 200) {
    dispatch(setState(json.data));
  }
  return json;
};

export const fetchNumberOfEmployees = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"EMPLOYEECOUNT"});
  if (json.status == 200) {
    dispatch(setNumberOfEmployee(json.data));
  }
  return json;
};

export const fetchAnnualTurnover = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"TURNOVER"});
  if (json.status == 200) {
    dispatch(setAnnualTurnover(json.data));
  }
  return json;
};

export const fetchIndustries = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"TYPE"});
  if (json.status == 200) {
    dispatch(setIndustries(json.data));
  }
  return json;
};

export const fetchCompanyByGtin = (query: object) => async (dispatch: any) => {
  const json = await searchCompanyByGTIN(query);
  if (json.status == 200) {
    dispatch(setCompany(json.data?.Company));
  }
  return json;
};

export const fetchClientSecret = (query: object) => async (dispatch: any) => {
  const json = await listClientSecret(query);
  if (json.status == 200) {
    dispatch(setClientSecret(json.data));
  }
  return json;
};

export const setContactValidate = (query: object) => async (dispatch: any) => {
  dispatch(contactValidate(query));
};

export const setShippingValidate = (query: object) => async (dispatch: any) => {
  dispatch(shippingValidate(query));
};

export const setPaymentValidate = (query: object) => async (dispatch: any) => {
  dispatch(paymentValidate(query));
};

export const {
  setLoader,
  setError,
  addForm,
  setIndex,
  orderSubmit,
  setCountry,
  setState,
  setNumberOfEmployee,
  setAnnualTurnover,
  setIndustries,
  setCompany,
  setAccountDetail,
  setClientSecret,
  contactValidate,
  shippingValidate,
  paymentValidate,
} = getStartedSlice.actions;

export const orderSelector = (state: RootState) => state.getStarted;

export default getStartedSlice.reducer;
