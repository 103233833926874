import React, { useState, useEffect } from "react";
import { HTTP_STATUS_CODES } from "../../../api/API";
import DashboardPopUp from "../../../dashboard-components/DashboardPopUp";
import { BarcodeInput, BarcodeSearchButton } from "./ProductScanNewStyle";
import { getProductBrickGVD } from "./ReclassifyAPI";
import { useFormFieldOnChange } from "../../../utils/FormHooks";
import ReclassifyDropdownsModal from "./ReclassifyDropdownsModal";
// import barcodeValidator from "barcode-validator";
import validbarcode from "barcode-validator";
import { isValid } from "barcode-validator";
const ErrorMessage = ({ message }) => {
  return (
    message?.trim().length > 0 && (
      <span
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "14px",
          marginTop: "5px",
        }}
      >
        {message}
      </span>
    )
  );
};

const ReclassifyModal = ({ productInfo, refreshCallback }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [GTINinput, , onGTINInputChange] = useFormFieldOnChange("");

  const [productScanned, setProductScanned] = useState(null);
  const [showPopUpModal, setShowPopUpModal] = useState(false);
  const [active, setActive] = useState(false);

  const handleSelectedRef = (value) => {
    alert("value is ");
  };
  // function responsible for comparing the product like for like
  const listProductDetails = async () => {
    if (!GTINinput.trim().length) return;

    // validate GTIN before sending request
    const validGTIN = validbarcode(GTINinput.trim());
    // The if statement will be used to check if the barcode is valid or not
    if (validGTIN) {
      setLoading(true);
      const res = await getProductBrickGVD(GTINinput);
      console.log("res", res);
      setLoading(false);
      setShowPopUpModal(true);

      setErrorMessage(null);
      if (res.status == HTTP_STATUS_CODES.NOT_FOUND) {
        setErrorMessage("Product Not On File.");
        return;
      } else if (res.status !== HTTP_STATUS_CODES.OK) {
        setErrorMessage("An error has occurred.");
        return;
      }

      setProductScanned(res?.data && res?.data);
      console.log("productScanned", res.data);
      console.log("you entered a valid GTIN");
    } else {
      setErrorMessage("Please enter a valid barcode.");
    }
  };

  useEffect(() => {
    console.log("mountd");
    return () => {};
  }, []);
  const SearchContainer = () => {
    const onSubmitSearchContainer = (e) => {
      e.preventDefault();

      if (!GTINinput) {
        // alert("Search");
        setProductScanned("");

        setShowPopUpModal(true);
      }
      // setLoading(true);

      listProductDetails();
      // setProductScanned(productScanned);
      // setShowPopUpModal(true);
    };

    return (
      productScanned == null && (
        <>
          <form onSubmit={onSubmitSearchContainer}>
            <BarcodeInput
              value={GTINinput}
              onChange={onGTINInputChange}
              autoFocus
              placeholder="Enter the Barcode/GTIN"
              type="text"
            />
            <BarcodeSearchButton>Search</BarcodeSearchButton>
          </form>
          <ErrorMessage message={errorMessage} />
        </>
      )
    );
  };

  return (
    <DashboardPopUp
      processing={loading}
      header={"Categorise This Product"}
      description={
        productScanned === null ? (
          <p>
            - Enter the barcode of a Like-for-Like Product
            <br />- Press Search without a barcode to open Categorise screen
          </p>
        ) : (
          <p>Select the Categories from the Drop Down Lists</p>
        )
      }
    >
      <SearchContainer />
      {productScanned != null && (
        <ReclassifyDropdownsModal
          refreshCallback={refreshCallback}
          productScanned={productScanned}
          productToBeUpdated={productInfo}
          setErrorMessage={setErrorMessage}
          setLoading={setLoading}
          active={active}
          handleSelectedRef={handleSelectedRef}
        >
          <ErrorMessage message={errorMessage} />
        </ReclassifyDropdownsModal>
      )}
    </DashboardPopUp>
  );
};

export default ReclassifyModal;
