import React, { useState } from 'react'
import { useContext } from 'react';
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { getBrickDetails } from '../../../../api/GroupVariantDetail';
import { useFormFieldOnChange } from '../../../../utils/FormHooks';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';

const BrickSearchContainer = () => {

  const [brickInput, setBrickInput, onChangeBrickInput] = useFormFieldOnChange('10000025');

  const [brickSelected, setBrickSelected] = useState({});

  const [loading, setLoading] = useState(false);

  const { setSelectedBrick } = useContext(ContextSelectedBrick);

  const searchBrick = () => {
    if (brickInput.trim().length == 0) {
      return false;
    }
    searchBrickDetails();
  }

  const searchBrickDetails = async () => {
    setLoading(true);
    setBrickSelected(null);

    const res = await getBrickDetails(brickInput);
    setLoading(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return false;
    }

    setBrickInput('');

    if (res.data.bricks.length == 0) return;

    setSelectedBrick(new String(brickInput));
    setBrickSelected({ BRICK: res.data.bricks[0].BrickCode, BrickDesc: res.data.bricks[0].BrickDesc });
  };

  const onPressEnterSearch = (e) => {
    if (e.key === 'Enter') {
      searchBrick()
    }
  };

  return (
    <>
      <span className="description">
        {loading && <>Loading...</>}
        {!loading && brickSelected == null && brickInput.trim().length > 0 && <>Brick not found</>}
        {!loading && brickSelected != null && <>Brick: {brickSelected.BRICK ? brickSelected.BRICK + ' - ' + brickSelected.BrickDesc : ''}</>}
      </span>

      <div className="search-container">
        <input onKeyDown={onPressEnterSearch} onChange={onChangeBrickInput} value={brickInput} className="brick-input" type="text" />
        <button onClick={searchBrick} className="brick-search-btn">Search Brick</button>
      </div>
    </>
  );
};

export default BrickSearchContainer;