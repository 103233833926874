import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DashboardPopUpContext } from "./context/DashboardPopUpContext";

const DashboardPopUp = ({
  header,
  description = "",
  children,
  processing = false,
  actions = null,
}) => {
  const { isPopUpVisible, hidePopUp } = useContext(DashboardPopUpContext);

  if (!isPopUpVisible()) {
    return null;
  }

  const closePopUp = (e) => {
    e.stopPropagation();
    hidePopUp();
  };

  const fakeClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="dashboard-popup-overlay" onClick={closePopUp}>
      <div className="dashboard-popup-container" onClick={fakeClick}>
        <div className="dashboard-popup-header">
          <div className="header-styling">
            <span className="header">{header}</span>
            <div>
              <p className="description">{description}</p>
            </div>
          </div>

          {actions !== null && <div className="actions">{actions}</div>}
          <FontAwesomeIcon
            className="close"
            icon={faTimes}
            color="#707070"
            onClick={closePopUp}
          />
        </div>
        <div className="dashboard-popup-body-container request-processing-indicator">
          <div className="body">{children}</div>

          {processing && (
            <div className="request-processing">
              <span>Processing...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPopUp;
