import { combineReducers } from '@reduxjs/toolkit';

import getStarted from './slices/getStartedSlice';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  getStarted
});

export default rootReducer;
