import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="NotLoggedInHomePageContainer">
      <div>
        <img style={{ width: "100%" }} src="BBA-Logo-TBl.png" alt="" />
        <div className="LinksContainer">
          <span className="NewMember">
            New member? <Link to="/SignUp">Sign Up</Link>
          </span>
          <span className="ExistingMember">
            {" "}
            Existing Member? <Link to="/SignIn">Sign In</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
