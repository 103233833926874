import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from  "../../store/store";
import { orderSelector } from "../../store/slices/getStartedSlice";
import GetStartedAccountPaymentComponent from "./GetStartedAccountPaymentComponent";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);



const GetStartedAccountPayment = () => {

  const navigate = useNavigate();

  const {

    clientSecret
  } = useAppSelector(orderSelector);

  useEffect(() => {
    if (!clientSecret) {
      navigate("/");
    }
  }, []);


  return (
    <>
      {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <GetStartedAccountPaymentComponent navigate={navigate} />
          </Elements>)}
    </>
  );
};

export default GetStartedAccountPayment;
