import React, { useContext, useState } from 'react';
import GVDItem from './GVDItem';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { GVDEditorAPI } from '../GVDEditorAPI';
import { HTTP_STATUS_CODES } from '../../../api/API';

const GVDItemAddGroup = ({ }) => {

  const { BRICK, refreshBrick } = useContext(ContextSelectedBrick);

  const onAddNew = async (description: string) => {
    if (!description.trim().length || !BRICK.trim().length) return;
    const res = await GVDEditorAPI.newGroup(description, BRICK);
    if (!res || res.status != HTTP_STATUS_CODES.CREATED) {
      return;
    }
    refreshBrick();
  };

  return (
    <GVDItem
      description={'Click on the \'+\' sign to a add new group'}
      onAddNew={onAddNew}
      onRename={false}
      onDelete={false} />
  );
};

export default GVDItemAddGroup;