import { getToken } from "../../../provider/AuthProvider";
import { getAuthRequest } from "../../../api/API";
// get brick for classification
const getProductBrickGVD = async (GTIN) => {
  try {
    return await getAuthRequest(getToken())
      .get("reclassifyproduct/brickgvd/" + GTIN)
      .then((res) => {
        console.log("res is ", res);
        return res;
      });
  } catch (error) {
    return error.response;
  }
};

const applyGVD = async (GTIN, BRICK, GroupingID, VariantID, DetailID) => {
  try {
    return await getAuthRequest(getToken())
      .post("reclassifyproduct/applygvd", {
        GTIN,
        BRICK,
        GroupingID,
        VariantID,
        DetailID,
      })
      .then((res) => {
        console.log("the applyGVD response is: ", res);
      });
  } catch (error) {
    return error.response;
  }
};

const getSegments = async () => {
  try {
    return await getAuthRequest(getToken()).get("reclassifyproduct/segment");
  } catch (error) {
    return error.response;
  }
};

const getFamilies = async (segment) => {
  try {
    return await getAuthRequest(getToken()).get(
      "reclassifyproduct/family/" + segment
    );
  } catch (error) {
    return error.response;
  }
};

const getClasses = async (family) => {
  try {
    return await getAuthRequest(getToken()).get(
      "reclassifyproduct/class/" + family
    );
  } catch (error) {
    return error.response;
  }
};

const getBricks = async (Class) => {
  try {
    return await getAuthRequest(getToken()).get(
      "reclassifyproduct/brick/" + Class
    );
  } catch (error) {
    return error.response;
  }
};

const getGvdGrouping = async (brick) => {
  try {
    return await getAuthRequest(getToken()).get(
      "reclassifyproduct/gvdGrouping/" + brick
    );
  } catch (error) {
    return error.response;
  }
};

const getGvdVariant = async (groupingID) => {
  try {
    return await getAuthRequest(getToken()).get(
      "reclassifyproduct/gvdVariant/" + groupingID
    );
  } catch (error) {
    return error.response;
  }
};

const getGvdDetail = async (variantID) => {
  try {
    return await getAuthRequest(getToken()).get(
      "reclassifyproduct/gvdDetail/" + variantID
    );
  } catch (error) {
    return error.response;
  }
};

export {
  getProductBrickGVD,
  applyGVD,
  getSegments,
  getFamilies,
  getClasses,
  getBricks,
  getGvdGrouping,
  getGvdVariant,
  getGvdDetail,
};
