import React, { useContext } from 'react';
import { assignParentCompanyToCompany } from '../../../api/BarcodeScan';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import ParentCompanyModal from './ParentCompanyModal';
import { BarcodeSearchButton } from './ProductScanNewStyle';

const ButtonShowParentModal = ({ CompanyID, refreshCallback }) => {

  const { showPopUp } = useContext(DashboardPopUpContext);

  const showCompromisedPopUp = () => {
    showPopUp(<ParentCompanyModal refreshCallback={refreshCallback} assignToCallbackParam={CompanyID} assingToCallBack={assignParentCompanyToCompany} />);
  }

  return (
    <BarcodeSearchButton onClick={showCompromisedPopUp}>Parent</BarcodeSearchButton>
  );
};

export default ButtonShowParentModal;