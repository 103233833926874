import React, { useState } from "react";
import { Auth } from "../../api/Auth";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import "./SignInPage.css";
import { HTTP_STATUS_CODES } from "../../api/API";

export default function SignInPage(props) {
  const userContext = useAuthContext();

  const [invalidLogin, setInvalidLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    if (processing) {
      return;
    }

    setProcessing(true);
    const req = await Auth().login(email, password);
    setInvalidLogin(req === false || req.status != HTTP_STATUS_CODES.OK);
    setProcessing(false);

    if (req.status == HTTP_STATUS_CODES.OK) {
      setEmail("");
      setPassword("");

      userContext.setUser(req.data.user);
      userContext.setToken({
        access_token: req.data.access_token,
        // 'token_type': req.data.token_type,
        // 'expires_in': req.data.expires_in
      });
    }
  }

  return (
    <div className="SignInPageContainer">
      <form onSubmit={handleSubmit}>
        <img
          style={{ width: "200px", marginBottom: "1rem", alignSelf: "center" }}
          src="BBA-Logo-TBl-S.png"
          alt=""
        />
        <p>Welcome back!</p>

        <div className="FormFieldRow">
          <label>Email</label>
          <input
            className={invalidLogin ? "Invalid" : ""}
            type="text"
            placeholder="Enter your email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="FormFieldRow">
          <label>Password</label>
          <input
            className={invalidLogin ? "Invalid" : ""}
            type="password"
            placeholder="Enter your password..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="FormButtonContainer">
          <button disabled={processing} onClick={handleSubmit}>
            Login
          </button>
        </div>

        <Link className="ForgotPW" to="/ForgotPassword">
          Forgot Password?
        </Link>
      </form>
    </div>
  );
}
