import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import SideMenuItem from "../SideMenuItem";
import { MenuGPC } from '../../api/MenuGPC';
import { HTTP_STATUS_CODES } from '../../api/API';
import { useLocation } from 'react-router-dom';
import GroupingItem from './GroupingItem';

const BrickItem = ({ segment, family, clss, brick }) => {

  const location = useLocation();

  const [groupings, setGroupings] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const loadGroupings = async (brickId) => {
    const res = await MenuGPC.getGroupings(brickId);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setGroupings(res.data);
  };

  useEffect(() => {
    const gpc = window.location.pathname.replace('/ProductsPage/', '').split('/');
    if (!gpc[3] || !gpc[3].match(/^\d+$/g)) {
      setIsSelected(false);
    } else {
      setIsSelected(gpc[3] == brick.ID);
    }
  }, [location]);

  return (
    <SideMenuItem subItemsLoader={async () => await loadGroupings(brick.ID)} isSelected={isSelected}>
      <Link to={"/ProductsPage/" + segment.ID + "/" + family.ID + "/" + clss.ID + "/" + brick.ID}>
        <span title={brick.ID + " : " + brick.Code + " : " + brick.Desc}>{brick.Desc ?? 'NOT DEFINED'}</span>
      </Link>
      {groupings.map((grouping, index) => <GroupingItem key={index} segment={segment} family={family} clss={clss} brick={brick} grouping={grouping} />)}
    </SideMenuItem>
  );
};

export default BrickItem;