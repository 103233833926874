import React, { useContext, useEffect, useState } from 'react'
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { getGroups } from '../../../../api/GroupVariantDetail';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItemGroup from './GVDItemGroup';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';

const GVDContainer = () => {

  const [groups, setGroups] = useState([]);

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail } = useContext(SelectedGVDDetailContext);

  useEffect(() => {
    fetchGroupVariantDetail();
  }, [BRICK]);

  useEffect(() => {
    // must should fetch groups again when a GVD is create or assigned 
    Object.entries(selectedDetail).length == 0 && fetchGroupVariantDetail();
  }, [selectedDetail]);

  const fetchGroupVariantDetail = async () => {
    setGroups([]);

    if ((BRICK?.trim().length ?? 0) == 0) return;

    const res = await getGroups(BRICK);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    setGroups(res.data);
  };

  return (
    <>
      <div className="gvd-details">
        <span className="container-title">
          <span className="title">Group Variant Detail</span>
        </span>
        <div className="container-body">
          {groups.length == 0 ? (
            <h4>No records</h4>
          ) : null}

          {groups.map((group, key) => (
            <GVDItemGroup key={key} group={group} />
          ))}
        </div>
      </div>
    </>
  );
};

export default GVDContainer;