import { getAuthRequest } from "../../api/API";
import { getToken } from "../../provider/AuthProvider";

const listProducts = async (skip, limit, sortInfo, filterValue, segmentId, familyId, classId, brickId, groupingId, variantId, detailId) => {
  try {
    let queryString = 'productgrid/products?page=' + skip + '&limit=' + limit;
    if (sortInfo) {
      queryString += '&sort=' + JSON.stringify(sortInfo);
    }
    if (filterValue) {
      queryString += '&filter=' + JSON.stringify(filterValue);
    }
    if (segmentId > 0) {
      queryString += '&segment=' + segmentId;
    }
    if (familyId > 0) {
      queryString += '&family=' + familyId;
    }
    if (classId > 0) {
      queryString += '&class=' + classId;
    }
    if (brickId > 0) {
      queryString += '&brick=' + brickId;
    }
    if (groupingId > 0) {
      queryString += '&grouping=' + groupingId;
    }
    if (variantId > 0) {
      queryString += '&variant=' + variantId;
    }
    if (detailId > 0) {
      queryString += '&detail=' + detailId;
    }

    return await getAuthRequest(getToken()).post(queryString);
  } catch (error) {
    return false;
  }
};

const reclassifyProduct = async (GTINs, BRICK) => {
  try {
    return await getAuthRequest(getToken()).post('productgrid/reclassify', {
      GTIN: GTINs, BRICK
    });
  } catch (error) {
    return error.response;
  }
};

const listSegments = async () => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/segments');
  } catch (error) {
    return error.response;
  }
};

const listFamilies = async (segmentId) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/families/' + segmentId);
  } catch (error) {
    return error.response;
  }
};

const listClasses = async (familyId) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/classes/' + familyId);
  } catch (error) {
    return error.response;
  }
};

const listBricks = async (classId) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/bricks/' + classId);
  } catch (error) {
    return error.response;
  }
};

const listBrands = async (companyID) => {
  try {
    return await getAuthRequest(getToken()).get('productgrid/brands/' + companyID);
  } catch (error) {
    return error.response;
  }
};

const assignBrand = async (GTINs, brandID) => {
  try {
    return await getAuthRequest(getToken()).post('productgrid/assignBrand', {
      GTIN: GTINs, BrandID: brandID
    });
  } catch (error) {
    return error.response;
  }
};

export { listProducts, reclassifyProduct, listSegments, listFamilies, listClasses, listBricks, listBrands, assignBrand };