import React, { useContext, useState } from 'react'
import { HTTP_STATUS_CODES } from '../../../api/API';
import { listGroupVars } from '../../../api/SupplierProducts';
import { BRICKFiltersContext } from '../contexts/BRICKFiltersContext';
import useListProduct from '../listProductsHook';
import GVDItem from './GVDItem';
import GVDVariantItem from './GVDVariantItem';
import ProductItem from './ProductItem';

const GVDGroupItem = ({ group }) => {

  const [products, fetchProducts] = useListProduct(group.ID)

  const { BRICKFilters, hideLoading, showLoading } = useContext(BRICKFiltersContext);

  const [variants, setVariants] = useState([]);

  const fetchVariants = async () => {
    showLoading();
    const res = await listGroupVars(group.GroupingDesc, BRICKFilters.SEGMENT, BRICKFilters.FAMILY, BRICKFilters.CLASS, BRICKFilters.BRICK)
    hideLoading();

    if (res.status != HTTP_STATUS_CODES.OK) return;

    setVariants(res.data);
  };

  return (
    <GVDItem hasChildren={group.Children == true} description={group.GroupingDesc} total={group.Total} fetchData={fetchVariants}>
      {group.Children == false && (
        <GVDItem level={1} description="No variants assigned" total={group.Total} fetchData={fetchProducts} hasChildren={true}>
          {products?.length == 0 && <span></span>}
          {products?.map((product, key) => (
            <ProductItem key={key} product={product} />
          ))}
        </GVDItem>
      )}
      {variants.map((variant, key) => (
        <GVDVariantItem key={key} group={group} variant={variant} hasChildren={variant.Children == true} fetchData={fetchVariants} />
      ))}
    </GVDItem>
  );
};
export default GVDGroupItem;