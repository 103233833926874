import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { useForm } from 'react-hook-form';
import { faAngleRight, faAngleDown, faPlusCircle, faPen, faTrash, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import GVDItemDeleteModal from './GVDItemDeleteModal';

type GVDItemProps = {
  description: string,
  children?: React.ReactNode,
  onAddNew: ((description: string) => Promise<void>) | false,
  onRename: ((description: string) => Promise<void>) | false,
  onDelete: (() => Promise<void>) | false,
  fetchSubItems?: () => Promise<void>,
}

const GVDItem = ({ description, children, fetchSubItems, onAddNew, onRename, onDelete }: GVDItemProps) => {

  const [hasRequestedData, setHasRequestedData] = useState(false);
  const [showItemSub, setShowItemSub] = useState(false);

  const toggleSubItem = async () => {
    const show = !showItemSub;
    setShowItemSub(show);

    if (!children) return;

    if (!hasRequestedData) {
      fetchSubItems && await fetchSubItems();
      setHasRequestedData(true);
    }
  };

  const statusIcon = () => !children ? null : <FontAwesomeIcon onClick={toggleSubItem} icon={showItemSub ? faAngleDown : faAngleRight} />;

  const EditableRow = ({ }) => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const { showPopUp } = useContext(DashboardPopUpContext);

    const [processing, setProcessing] = useState(false);
    const [action, setAction] = useState('');

    const cancelOp = () => {
      setAction('')
      setValue('name', description);
    };

    const renameItem = () => setAction('renameItem');

    const addSubItem = () => {
      setAction('addNew');
      setValue('name', '');
    };

    const saveItem = async () => {
      setProcessing(true);
      if (action == 'renameItem') {
        onRename && await onRename(watch('name'));
      }
      else if (action == 'addNew' && onAddNew) {
        await onAddNew(watch('name'));
      }
      setProcessing(false);
      setAction('');
    };

    const onClickDelete = async () => {
      onDelete && await onDelete();
    };

    const deleteItem = () => {
      showPopUp(<GVDItemDeleteModal onClickYes={onClickDelete} />);
    };

    const actionIcon = (faIcon: IconDefinition, description: string, click: () => void) => {
      return <FontAwesomeIcon title={description} icon={faIcon} onClick={click} />;
    };

    useEffect(() => {
      setValue('name', description);
    }, []);

    return (
      <div className="gvd-item-container request-processing-indicator" data-has-children={!!children}>
        {statusIcon()}
        {!action.trim().length && (
          <>
            <span className="description">{watch('name') ?? 'Not Defined'}</span>
            <div className="actions-container">
              {onAddNew && actionIcon(faPlusCircle, 'Add New', addSubItem)}
              {onRename && actionIcon(faPen, 'Rename', renameItem)}
              {onDelete && actionIcon(faTrash, 'Delete', deleteItem)}
            </div>
          </>
        )}
        {action.trim().length > 0 && (
          <>
            <form onSubmit={handleSubmit(saveItem)}>
              <input
                autoFocus
                {...register('name', { required: true })}
                style={{ width: '250px' }}
                placeholder={action == 'addNew' ? 'Type a description for the new record' : 'Enter a new description for the record'}
                className="name" type="text" defaultValue={description} />
            </form>
            <div className="actions-container">
              {actionIcon(faTimesCircle, 'Cancel', cancelOp)}
              {actionIcon(faSave, action == 'addNew' ? 'Create' : 'Save', saveItem)}
            </div>
          </>
        )}
        {processing && (
          <div className="request-processing">
            <span>Processing ...</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="gvd-tree-item-container">
        <EditableRow />
        {showItemSub && children && (
          <div className="gvd-tree-item-sub">
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default GVDItem;