import React from 'react';
import DashboardPopUp from '../../../../dashboard-components/DashboardPopUp';

import './RawWordsProductList.scss';

const RawWordsProductList = ({ prods, wordMatch }) => {

  return (
    <DashboardPopUp header={"Products"} description={wordMatch.Words.join(' - ')}>
      <div className="gvd-words-list products-list">
        <span className="brand">Brand</span>
        <span className="description">Description</span>
        {prods.map((prod, key) => (
          <React.Fragment key={key}>
            <span>{prod.Brand} - {prod.BrandSub}</span>
            <span>{prod.TradeItemDesc}</span>
          </React.Fragment>
        ))}
      </div>
    </DashboardPopUp>
  );

};
export default RawWordsProductList;