import React, { useEffect, useState } from 'react';
import { getProduct, getSimilarProducts } from '../../../api/ProductScan';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './TestingSearch.css';

const TestingSearch = () => {

  const [GTIN, setGTIN, onGTINInputChange] = useFormFieldOnChange('');
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);


  useEffect(() => loadProducts.load(GTIN), [GTIN]);

  const [loadProducts] = useState(() => {
    const timeoutFn = (_GTIN) => {
      return async () => {
        setProducts([]);
        setProduct(null);
        if (_GTIN.trim().length < 5) return;
        const res = await getProduct(_GTIN);
        if (res !== false) {
          const product = res.data;
          setProduct(product);
          const resProds = await getSimilarProducts(product.BRICK, product.FunctionalName, product.Variant);
          if (resProds !== false) {
            setProducts(resProds.data);
          }
        }
      };
    };

    let timeout = null;

    return {
      load: (GTIN) => {
        clearTimeout(timeout);
        timeout = setTimeout(timeoutFn(GTIN), 1000);
      }
    };
  });


  return (
    <PageSkeletonComponent>

      <h1>TESTING - Product Search</h1>

      <hr />

      <h2>Purpose of Page</h2>
      Testing of the Product Scan Search.

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

      <br /><br />

      <hr />

      <br />

      <label for="GTIN"><b>GTIN (Barcode): </b></label>

      <input 
        type="text" 
        id="GTIN" 
        name="GTIN"
        // value="09316086000010"
        onChange={onGTINInputChange}></input><br /><br />

      {product !== null && (
        <>
          <table width="60%" border="1">
            <tr>
              <th width="20%">
                <img src="https://www.fleurieumilkco.com.au/wp-content/uploads/2019/09/Cream-300ML.png" height="100" alt="Fleurieu Peninsula Milk - Pouring Cream 300ml"></img>
              </th>
              <th width="60%" border="1">
                <tr>
                  <th><b>Brand: </b></th>
                  <th>{product.Brand} - {product.BrandSub}</th>
                </tr>
                <tr>
                  <th><b>Description: </b></th>
                  <th>{product.TradeItemDesc}</th>
                </tr>
                <tr>
                  <th><b>Third Row: </b></th>
                  <th>??</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th><b>Local Content: </b></th>
                  <th>100 %</th>
                </tr>
                <tr>
                  <th><b>Origin: </b></th>
                  <th>AUS</th>
                </tr>
                <tr>
                  <th><b>Ownership: </b></th>
                  <th>AUS</th>
                </tr>
              </th>
            </tr>
          </table>


          <table width="60%" border="1">
            <tr>
              <th>
                <b>Brick: </b>{product.BRICK}
              </th>
              <th>
                <b>Functional Group: </b>{product.FunctionalName}
              </th>
              <th>
                <b>Variant: </b>{product.Variant}
              </th>
            </tr>
          </table>
        </>
      )}

      <br />

      <hr />

      <h3>Filters</h3>
      <label for="states">Choose State: </label>
      <select name="states" id="states">
        <option value="ACT">Australian Capital Territory</option>
        <option value="NSW">New South Wales</option>
        <option value="NT">Northern Territory</option>
        <option value="QLD">Queensland</option>
        <option value="SA">South Australia</option>
        <option value="TAS">Tasmania</option>
        <option value="VIC">Victoria</option>
        <option value="WA">Western Australia</option>
      </select>

      <br /><br />

      <table width="60%" border="1">
        <tr>
          <th>
            <b>Search: </b>
          </th>
          <th>
            <button class="button button1">Search</button>
          </th>
        </tr>
      </table>

      <br />

      {products.length > 0 && (
        <table width="60%" border="1">
          {products.map((currentProduct) => (
            <tr key={currentProduct.ProductID}>
              <th width="20%">
                <img src="https://www.fleurieumilkco.com.au/wp-content/uploads/2019/09/Cream-300ML.png" height="100" alt="Fleurieu Peninsula Milk - Pouring Cream 300ml"></img>
              </th>
              <th width="60%" border="1">
                <tr>
                  <th><b>Brand: </b></th>
                  <th>{currentProduct.Brand} - {currentProduct.SubBrand}</th>
                </tr>
                <tr>
                  <th><b>Description: </b></th>
                  <th>{currentProduct.TradeItemDesc}</th>
                </tr>
                <tr>
                  <th><b>Third Row: </b></th>
                  <th>??</th>
                </tr>
              </th>
              <th>
                <tr>
                  <th><b>Local Content: </b></th>
                  <th>100 %</th>
                </tr>
                <tr>
                  <th><b>Origin: </b></th>
                  <th>AUS</th>
                </tr>
                <tr>
                  <th><b>Ownership: </b></th>
                  <th>AUS</th>
                </tr>
              </th>
            </tr>
          ))}
        </table>
      )}


    </PageSkeletonComponent>
  );
};

export default TestingSearch;
