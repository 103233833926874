import React, { useEffect, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../api/API';
import { getCompaniesAssignedToPrefix } from '../../../api/BarcodeScan';
import DashboardPopUp from '../../../dashboard-components/DashboardPopUp';

import styled from 'styled-components'
const CompanyDescContainer = styled.div`
  & span {
    font-weight: bold;
  }

  &:not(:last-child) {
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
`;

const PrefixModal = ({ prefix }) => {

  const [loading, setLoading] = useState(false);

  const [companies, setCompanies] = useState(null);

  const searchCompanies = async () => {
    setLoading(true);

    const res = await getCompaniesAssignedToPrefix(prefix.ID);

    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) return false;

    setCompanies(res.data);
  };

  useEffect(() => {
    searchCompanies();
  }, []);

  return (
    <DashboardPopUp processing={loading} header={"Companies using prefix - " + prefix.Prefix}>
      {companies?.map((value, index) => (
        <CompanyDescContainer>
          <div><span>ID:</span> {value.CompanyID}</div>
          <div><span>GLN:</span> {value.EntityGLN ?? 'NOT DEFINED'}</div>
          <div><span>Name:</span> {value.CompanyName}</div>
        </CompanyDescContainer>
      ))}
    </DashboardPopUp>
  );
};

export default PrefixModal;