import { createContext, useContext, useState } from 'react';
import {
  isLoggedIn,
  getUser,
  setUser as setUserProvider,
  getToken,
  setToken as setTokenProvider,
  logoutUser as logoutUserProvider
} from '../provider/AuthProvider';

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export const useAuthState = () => {
  const [isUserLoggedIn, setIsLoggedIn] = useState(isLoggedIn());

  const [user, setUserState] = useState(getUser());

  const [token, setTokenState] = useState(getToken());

  const logoutUser = () => {
    logoutUserProvider();
    setIsLoggedIn(false);
  };

  const setUser = (user) => {
    setUserProvider(user);
    setUserState(user);
  };

  const setToken = (token) => {
    setTokenProvider(token);
    setTokenState(token);
    setIsLoggedIn(true);
  };

  const isUserSupplier = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isSupplier && !user.isApprovalBeingProcessed;
  };

  const isUserRetailer = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isRetailer && !user.isApprovalBeingProcessed;
  };

  const isUserServiceProvider = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isServiceProvider && !user.isApprovalBeingProcessed;
  };

  const isEmployee = () => {
    return isUserLoggedIn && user.isEmployee;
  };

  return { isUserLoggedIn, isUserSupplier, isEmployee, isUserRetailer, isUserServiceProvider, user, setUser, token, setToken, logoutUser };
};