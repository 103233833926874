import React, { useContext, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { processIgnoresSubstitutes } from '../../../../api/GroupVariantDetail';
import { DashboardPopUpContext } from '../../../../dashboard-components/context/DashboardPopUpContext';
import DashboardPopUp from '../../../../dashboard-components/DashboardPopUp';

import './RawWordsIgnoreSubstitute.scss';

const RawWordsIgnoreSubstitute = ({ ignoresSubstitutes, wordMatch, onWordMatchUpdate }) => {
  const { hidePopUp } = useContext(DashboardPopUpContext);

  const [formControl, setFormControl] = useState({ ...ignoresSubstitutes })
  const [processing, setProcessing] = useState(false);

  const onIgnoredChange = (e, word) => {
    const currentState = formControl;
    currentState[word].Ignored = e.target.checked == true;
    currentState[word]['Substitute'] = [];
    setFormControl({ ...currentState })
  };

  const onSubstituteChange = (e, word, substituteField) => {
    const currentState = formControl;
    currentState[word].Ignored = false;
    currentState[word]['Substitute'][substituteField] = e.target.value;;
    setFormControl({ ...currentState })
  };

  const submitPopUp = async () => {
    setProcessing(true);
    const res = await processIgnoresSubstitutes(wordMatch.WordMatchID, ignoresSubstitutes);
    setProcessing(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    onWordMatchUpdate(res.data);
    hidePopUp();
  };

  const createRows = () => {
    const CreateNewRow = (word, key) => {
      return (
        <React.Fragment key={key}>
          <span className="word-description">{word}</span>

          <input
            className="word-ignore-checkbox"
            checked={ignoresSubstitutes[word].Ignored}
            onChange={(e) => onIgnoredChange(e, word)}
            type="checkbox" />

          <input
            className="word-substitute-input"
            value={ignoresSubstitutes[word][0]}
            maxLength={25}
            onChange={(e) => onSubstituteChange(e, word, 0)}
            type="text" />

          <input
            className="word-substitute-input"
            value={ignoresSubstitutes[word][1]}
            maxLength={25}
            onChange={(e) => onSubstituteChange(e, word, 1)}
            type="text" />

          <input
            className="word-substitute-input"
            value={ignoresSubstitutes[word][2]}
            maxLength={25}
            onChange={(e) => onSubstituteChange(e, word, 2)}
            type="text" />

        </React.Fragment>
      );
    };

    return wordMatch.Words.map((word, key) => CreateNewRow(word, key));
  };

  return (
    <DashboardPopUp header={"Edit the words"} processing={processing}>

      <div className="gvd-words-list ignore-substitute">
        <span></span>
        <span className="ignore">Ignore</span>
        <span className="substitute">Substitute</span>
        {createRows()}
        <button className="confirm-btn" onClick={submitPopUp}>Confirm</button>
      </div>

    </DashboardPopUp>
  );

};
export default RawWordsIgnoreSubstitute;