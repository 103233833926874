import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import YouTube from 'react-youtube';
import { useFormFieldUtils } from '../../utils/FormHooks';
import { useState } from 'react';
import { investorExpressionInterest } from '../../api/SignUp';
import { HTTP_STATUS_CODES } from '../../api/API';
import './GetStartedInvestorExpressionOfInterest.scss';

const GetStartedInvestorExpressionOfInterest = () => {

  const userAuth = useAuthContext();
  const user = userAuth.user;

  const navigate = useNavigate();

  const [secsToNextStep, setSecsToNextStep] = useState(null);

  const firstNameInput = useFormFieldUtils(user.NameFirst);
  const emailInput = useFormFieldUtils(user.Email);
  const amountInput = useFormFieldUtils('');
  const questions = useFormFieldUtils('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  useEffect(() => {
    if (secsToNextStep == null) return;
    if (secsToNextStep <= 0) {
      navigate('/GetStarted/AccountWaitingApproval');
      return;
    }
    setTimeout(() => {
      setSecsToNextStep(secsToNextStep - 1);
    }, 1000);
  }, [secsToNextStep]);

  const sendNextStep = (e) => {
    e.preventDefault();
    setSecsToNextStep(3);
  };

  const submitForm = (event) => {
    event.preventDefault();
    setSubmissionError(false);
    setIsSubmitting(false);

    const fields = [
      firstNameInput, emailInput, amountInput, questions
    ];

    const checkIsValid = (value) => value?.trim().length > 0;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let isValid = checkIsValid(field.value);

      field.setIsFieldValid(true);
      isValid = field.checkIsValid(checkIsValid);

      if (!isValid) return;
    }
    callInvestorExpressionInterest();
  };

  const callInvestorExpressionInterest = async () => {
    setIsSubmitting(true)
    const res = await investorExpressionInterest(firstNameInput.value, emailInput.value, amountInput.value, questions.value);
    setIsSubmitting(false);
    if (res.status != HTTP_STATUS_CODES.NO_CONTENT) {
      setSubmissionError(true);
      return;
    }
    setSecsToNextStep(3);
  };

  return (
    <DashboardContainer title={"Investor Expression of Interest"}>
      <div className="iei-container">

        <div className="overview">
          Before you go... have you got a couple of minutes...
        </div>

        <div className="overview">
          <b>Would you like to be a founding shareholder in Bring Back Australia?</b>
        </div>

        <div className="overview">
          Shortly we will be launching our Equity Crowdfunding Offer which will give the public the opportunity to
          invest in Bring Back Australia and help us to launch our app and bring buying Australian Owned to the masses.
        </div>

        <div className="overview">
          Being a shareholder and part of the movement in raising awareness of Australian Owned businesses and
          Australian Made products is an opportunity that does not come around every day.  We would love to have you
          onboard to help us share the message and deliver the solution.
        </div>

        <div className="video">
          <YouTube
            opts={{
              width: '70%'
            }}
            videoId={"agQVxiEru8Q"} />
        </div>

        <div className="overview">
          If you would like to become a shareholder in Bring Back Australia and help us to launch this app then it's as
          easy as entering your details below.
        </div>

        <div className="overview">
          Please note, this is a "registration of interest" only, not a firm commitment, this way you will receive
          further information as part of our exclusive pre-release before we launch our offer publicly.
        </div>

        <form onSubmit={submitForm} className="form" style={{ margin: '0 auto', width: '55%' }}>
          <table style={{ width: '100%' }}>
            <tr>
              <td><label>Name</label></td>
              <td><input ref={firstNameInput.ref} className={firstNameInput.isValid ? '' : 'required'} onChange={firstNameInput.onChange} value={firstNameInput.value} type="text" /></td>
            </tr>
            <tr>
              <td><label>Email</label></td>
              <td><input ref={emailInput.ref} className={emailInput.isValid ? '' : 'required'} onChange={emailInput.onChange} value={emailInput.value} type="text" /></td>
            </tr>
            <tr>
              <td><label>Amount</label></td>
              <td><input ref={amountInput.ref} className={amountInput.isValid ? '' : 'required'} onChange={amountInput.onChange} value={amountInput.value} type="text" maxLength={15} /></td>
            </tr>
            <tr>
              <td><label>Questions</label></td>
              <td>
                <textarea ref={questions.ref} className={questions.isValid ? '' : 'required'} onChange={questions.onChange} value={questions.value} rows={10} />
              </td>
            </tr>
            {submissionError && <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: 'red' }}>
                Something went wrong, please contact our support!
              </td>
            </tr>}
            {isSubmitting && <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
                We are processing your request ...
              </td>
            </tr>}
            {secsToNextStep !== null && <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
                All done! You will be taken the dashboard in {secsToNextStep} second(s)
              </td>
            </tr>}
            {secsToNextStep == null && !isSubmitting && <tr>
              <td colSpan="2" className="action-footer right">
                <button onClick={sendNextStep}>Skip</button>
                <button onClick={submitForm}>Submit</button>
              </td>
            </tr>}
          </table>
        </form>
      </div>
    </DashboardContainer >
  );
};

export default GetStartedInvestorExpressionOfInterest;