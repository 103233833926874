import React, { useContext, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { getGroupVarDetails } from '../../../../api/GroupVariantDetail';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItem from './GVDItem';
import GVDItemDetail from './GVDItemDetail';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';

const GVDItemVariant = ({ group, variant }) => {

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail, setSelectedDetail } = useContext(SelectedGVDDetailContext);

  const [details, setDetails] = useState([]);

  const fetchData = async () => {
    const res = await getGroupVarDetails(BRICK, group.GroupingID, variant.VariantID);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    setDetails(res.data);
  };

  const isSelected = () => selectedDetail.GroupingID == group.GroupingID && selectedDetail.VariantID == variant.VariantID;

  const onClick = () => {
    setSelectedDetail({
      GroupingID: group.GroupingID, GroupingDesc: group.GroupingDesc,
      VariantID: variant.VariantID, VariantDesc: variant.VariantDesc,
      DetailID: '', DetailDesc: ''
    })
  };

  return (
    <GVDItem
      description={variant.VariantDesc}
      total={variant.Total}
      onClick={onClick}
      fetchData={fetchData}
      isSelected={isSelected}>

      {details.map((detail, key) => <GVDItemDetail key={key} group={group} variant={variant} detail={detail} />)}

    </GVDItem>
  );
};

export default GVDItemVariant;