import { format } from 'date-fns';
import React from 'react';
import ReactCountryFlag from "react-country-flag"

const renderCountryFlag = (value, data, title) => {
  // if it is New Zealand show a speficic image
  if (value == 'NZ') {
    return <ReactCountryFlag
      countryCode={value}
      svg
      cdnUrl={window.location.origin + "/country-flags/"}
      style={{
        width: '2em',
        height: '2em',
        objectFit: 'cover'
      }}
      title={title}
    />
  }
  return <ReactCountryFlag
    countryCode={value}
    svg
    style={{
      width: '2em',
      height: '2em',
    }}
    title={title}
  />
};

const defaultColumns = [
  { name: 'GTIN', minWidth: 100, header: 'GTIN', defaultFlex: 1, type: 'number' },
  { name: 'ProductID', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
  { name: 'CompanyID', header: 'CompanyID', defaultVisible: false, type: 'number' },
  { name: 'CompanyName', minWidth: 100, header: 'Supplier', defaultFlex: 1 },
  { name: 'Brand', minWidth: 100, header: 'Brand', defaultFlex: 1 },
  { name: 'BrandSub', minWidth: 100, header: 'Sub Brand', defaultFlex: 1 },
  { name: 'TradeItemDesc', minWidth: 100, header: 'Description', defaultFlex: 3 },
  {
    name: 'AustralianContent', defaultWidth: 96, header: 'Content', render: ({ value }) => {
      return value ? value + '%' : '';
    }
  },
  {
    name: 'OriginCountryN2', defaultWidth: 85, header: 'Origin', render: ({ value, data }) => renderCountryFlag(value, data, data.ProductCountry)
  },
  {
    name: 'CompanyCountryN2', defaultWidth: 116, header: 'Registered', render: ({ value, data }) => renderCountryFlag(value, data, data.CompanyName + ' - ' + data.CompanyAddressCountry)
  },
  {
    name: 'ParentCompanyCountryN2', defaultWidth: 87, header: 'Parent', render: ({ value, data }) => renderCountryFlag(value, data, data.ParentCompany + ' - ' + data.ParentAddressCountry)
  },
  // {
  //   name: 'LastUpdate', header: 'Last Update', type: 'date', defaultFlex: 1, render: ({ value }) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
  // },
];

export default defaultColumns;