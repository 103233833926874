import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

export const Brands = () => {
  const getAll = async (gln) => {
    try {
      //when the logged user is not an employee of Bring Back, the GLN filter should pick up automatically the GLN the user is related to
      gln = gln == null ? '' : gln;
      return await getAuthRequest(getToken()).get('brands/getAll/' + gln);
    } catch (error) {
      return false;
    }
  };

  const getSubstitutions = async (brandId) => {
    try {
      //when the logged user is not an employee of Bring Back, the GLN filter should pick up automatically the GLN the user is related to
      brandId = brandId == null ? '' : brandId;
      return await getAuthRequest(getToken()).get('brands/getSubstitutions/' + brandId);
    } catch (error) {
      return false;
    }
  };

  const validateBrand = async (brandID) => {
    try {
      return await getAuthRequest(getToken()).post('brands/validateBrand', {
        'BrandID': brandID
      });
    } catch (error) {
      return false;
    }
  };

  const addSubstitute = async (brandID, subtituteID) => {
    try {
      return await getAuthRequest(getToken()).post('brands/addBrandSubstitute', {
        'BrandID': brandID, 'SubstituteID': subtituteID
      });
    } catch (error) {
      return false;
    }
  }

  const checkBrandAlreadyExists = async (GLN, brand, brandSub) => {
    try {
      return await getAuthRequest(getToken()).post('brands/checkBrandAlreadyExists', {
        'GLN': GLN, 'Brand': brand, 'BrandSub': brandSub
      });
    } catch (error) {
      return error.response;
    }
  }

  const createBrandSubstitute = async (GLN, brandID, brand, brandSub) => {
    try {
      return await getAuthRequest(getToken()).post('brands/createBrandSubstitute', {
        'GLN': GLN, 'BrandID': brandID, 'Brand': brand, 'BrandSub': brandSub
      });
    } catch (error) {
      return error.response;
    }
  }


  return { getAll, getSubstitutions, validateBrand, addSubstitute, checkBrandAlreadyExists, createBrandSubstitute };
};