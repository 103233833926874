import React, { useContext, useState } from 'react'
import { HTTP_STATUS_CODES } from '../../../api/API';
import { listGroupVarDetails } from '../../../api/SupplierProducts';
import { BRICKFiltersContext } from '../contexts/BRICKFiltersContext';
import useListProduct from '../listProductsHook';
import GVDDetailItem from './GVDDetailItem';
import GVDItem from './GVDItem';
import ProductItem from './ProductItem';

const GVDVariantItem = ({ variant, group }) => {

  const [products, fetchProducts] = useListProduct(group.ID)

  const { BRICKFilters, hideLoading, showLoading } = useContext(BRICKFiltersContext);

  const [details, setDetails] = useState([]);

  const fetchDetails = async () => {
    showLoading();
    const res = await listGroupVarDetails(group.GroupingDesc, variant.VariantDesc, BRICKFilters.SEGMENT, BRICKFilters.FAMILY, BRICKFilters.CLASS, BRICKFilters.BRICK)
    hideLoading();

    if (res.status != HTTP_STATUS_CODES.OK) return;

    setDetails(res.data);
  };

  return (
    <GVDItem level={1} description={variant.VariantDesc} total={variant.Children ? variant.Total : null} hasChildren={variant.Children == true} fetchData={fetchDetails}>
      {variant.Children == false && (
        <GVDItem level={2} description="No details assigned" total={products?.length} fetchData={fetchProducts} hasChildren={true}>
          {products?.length == 0 && <span></span>}
          {products?.map((product, key) => (
            <ProductItem key={key} product={product} />
          ))}
        </GVDItem>
      )}
      {details.map((detail, key) => (
        <GVDDetailItem key={key} detail={detail} />
      ))}
    </GVDItem>
  )
};
export default GVDVariantItem;