import styled from 'styled-components'

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
`;

export const RowItemStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  gap:4px;

  padding: 5px;
  cursor: pointer;

  &:nth-child(odd) {
    background-color: #cce4dc;
  }

  &:hover,
  &.selected {
    background-color: #707070;
    color: white;

    & svg {
      color: white;
    }
  }

  &.selected {
    background-color: #1a6f54;
  }

  & > span {
    flex-grow: 1;
  }
`;