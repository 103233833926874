import * as React from 'react';
import { useForm } from 'react-hook-form';
import { HTTP_STATUS_CODES } from '../../../api/API';
import { getBrickDetails } from '../../../api/GroupVariantDetail';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';

const BrickSearchContainer = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const { BRICK, BrickDesc, setSelectedBrick, setSelectedBrickDesc } = React.useContext(ContextSelectedBrick);

  const [loading, setLoading] = React.useState(false);

  const searchBrick = () => {
    if (watch('brick').toString().trim().length == 0) {
      return false;
    }

    setSelectedBrick(watch('brick'));
  }

  React.useEffect(() => {
    if (!BRICK.trim().length) return;
    searchBrickDetails();
  }, [BRICK]);

  const searchBrickDetails = async () => {
    setLoading(true);

    const res = await getBrickDetails(BRICK);
    setLoading(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return false;
    }

    setValue('brick', '');

    if (res.data.bricks.length == 0) return;

    // setSelectedBrick(res.data.bricks[0].BRICK.toString());
    setSelectedBrickDesc(res.data.bricks[0].BrickDesc);
  };

  return (
    <div className="gvd-validation-search">
      <span className="description">
        {loading && <>Loading...</>}
        {!loading && BRICK == null && watch('brick').trim().length > 0 && <>Brick not found</>}
        {!loading && BRICK != null && <>Brick: {BRICK ? BRICK + ' - ' + BrickDesc : ''}</>}
      </span>

      <div className="search-container">
        <form onSubmit={handleSubmit(searchBrick)}>
          <input {...register('brick', { required: true })} defaultValue="10000025" className="brick-input" type="text" />
          <button className="brick-search-btn">Search Brick</button>
        </form>
      </div>
    </div>
  );
};

export default BrickSearchContainer;