import { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { MenuGPC } from "../../api/MenuGPC";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import React from "react";
import SideMenuItem from "../SideMenuItem";
import ClassItem from "./ClassItem";

const FamilyItem = ({ segment, family }) => {

  const location = useLocation();

  const [classes, setClasses] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const loadClasses = async (familyId) => {
    const res = await MenuGPC.getClasses(familyId);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setClasses(res.data);
  };

  useEffect(() => {
    const gpc = window.location.pathname.replace('/ProductsPage/', '').split('/');
    if (!gpc[1] || !gpc[1].match(/^\d+$/g)) {
      setIsSelected(false);
    } else {
      setIsSelected(gpc[1] == family.ID);
    }
  }, [location]);

  return (
    <SideMenuItem subItemsLoader={async () => await loadClasses(family.ID)} isSelected={isSelected}>
      <Link to={"/ProductsPage/" + segment.ID + "/" + family.ID}>
        <span title={family.ID + " : " + family.Code + " : " + family.Desc}>{family.Desc ?? 'NOT DEFINED'}</span>
      </Link>
      {classes.map((clss, index) => <ClassItem key={index} segment={segment} family={family} clss={clss} />)}
    </SideMenuItem>
  );
};

export default FamilyItem;