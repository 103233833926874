import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CompleteRegistration = () => {
  const navigate = useNavigate();
  let [counter, setCounter] = useState(5);

  const takeToLogin = (e) => {
    e.preventDefault();
    navigate("/SignIn");
  };

  useEffect(() => {

  const countdownInterval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        navigate("/SignIn")
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [counter]);

  return (
    <div className="SignUpPageContainer">
      <form>
        <img
          style={{ width: "200px", marginBottom: "1rem", alignSelf: "center" }}
          src="BBA-Logo-TBl-S.png"
          alt=""
        />
        <p>Congratulations, your account has been verified!</p>
        <p style={{fontWeight:"normal"}}>You are being redirected to Login page in {counter} seconds.</p>
        {/* <div className="FormButtonContainer">
          <button onClick={takeToLogin}>Take me to the login</button>
        </div> */}
      </form>
    </div>
  );
};

export default CompleteRegistration;
