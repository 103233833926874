import React, { useContext, useEffect, useState } from 'react'
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { getAssignedWords } from '../../../../api/GroupVariantDetail';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';

const AssignedWordsContainer = () => {

  const [words, setWords] = useState([]);

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail } = useContext(SelectedGVDDetailContext);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    (BRICK?.trim().length ?? 0) > 0 && fetchWords();
  }, [selectedDetail]);

  useEffect(() => {
    setWords([]);
  }, [BRICK]);

  const fetchWords = async () => {
    setWords([]);

    if (selectedDetail.GroupingID == '' || selectedDetail.VariantID == '' || selectedDetail.DetailID == '') return;

    setProcessing(true);
    const res = await getAssignedWords(BRICK, selectedDetail.GroupingID, selectedDetail.VariantID, selectedDetail.DetailID);
    setProcessing(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    setWords(res.data);
  };

  const getNewRow = (word, index) => {
    return (
      <div key={index} className="gvd-word">
        <span className="gvd-word-description">{word.Words.join(' - ')}</span>
      </div>
    )
  };

  return (
    <>
      <div className="gvd-assigned-words">
        <span className="container-title column">
          <span className="title">Assigned Words</span>
          {
            selectedDetail.GroupingID && <span className="sub-title">
              {selectedDetail.GroupingDesc} {selectedDetail.VariantDesc ? '> ' + selectedDetail.VariantDesc : ''} {selectedDetail.DetailDesc ? '> ' + selectedDetail.DetailDesc : ''}
            </span>
          }
        </span>
        <div className="gvd-words-list container-body request-processing-indicator">
          {!processing && words.length == 0 ? (
            <h4>No records</h4>
          ) : null}

          {words.map((word, index) => getNewRow(word, index))}

          {processing && (
            <div className="request-processing">
              <span>Loading...</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignedWordsContainer;