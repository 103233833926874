import React, { useContext } from 'react';
import { assignParentCompanyToPrefix } from '../../../api/BarcodeScan';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import ParentCompanyModal from './ParentCompanyModal';
import { BarcodeSearchButton } from './ProductScanNewStyle';

const ButtonShowCompromisedModal = ({ GTIN, refreshCallback }) => {

  const { showPopUp } = useContext(DashboardPopUpContext);

  const showCompromisedPopUp = () => {
    showPopUp(<ParentCompanyModal refreshCallback={refreshCallback} assignToCallbackParam={GTIN} assingToCallBack={assignParentCompanyToPrefix} />);
  }

  return (
    <BarcodeSearchButton onClick={showCompromisedPopUp}>Compromised</BarcodeSearchButton>
  );
};

export default ButtonShowCompromisedModal;