import React, { useState } from 'react'
import { Importer, ImporterField } from 'react-csv-importer';
import DashboardContainer from '../../../dashboard-components/DashboardContainer';

import "react-csv-importer/dist/index.css"
import { finish, importProds, start } from '../../../api/ImportFileProducts';
import { HTTP_STATUS_CODES } from '../../../api/API';

const ProductImport = () => {

  // const [updateOverviewID, setUpdateOverviewID] = useState(null);
  let updateOverviewID = null;

  const startImport = () => {
    // setUpdateOverviewID(new Promise((resolve, reject) => {
    //   const _startImport = async () => {
    //     const res = await start();
    //     if (res.status != HTTP_STATUS_CODES.OK) {
    //       resolve(false);
    //       // HOW TO STOP THE PROCESS WHEN A POTENTIAL ERROR OCCURS?
    //       return;
    //     }
    //     resolve(res.data);
    //   };
    //   _startImport();
    // }))

    updateOverviewID = new Promise((resolve, reject) => {
      const _startImport = async () => {
        const res = await start();
        if (res.status != HTTP_STATUS_CODES.OK) {
          resolve(false);
          // HOW TO STOP THE PROCESS WHEN A POTENTIAL ERROR OCCURS?
          return;
        }
        resolve(res.data);
      };
      _startImport();
    });
  };

  const importProducts = async (rows) => {
    let importID = await updateOverviewID;
    if (importID == false) return;

    while (rows.length > 0) {
      // sends 500 products at a time
      const chunck = rows.splice(0, 500);
      const res = await importProds(importID.UpdateID, chunck);
      if (res.status != HTTP_STATUS_CODES.OK) {
        // PRINT ERRORs THAT MIGHT HAVE HAPPENED DURING THE IMPORT PROCESS
      }
    }
  };

  const finishImport = async () => {
    let importID = await updateOverviewID;
    if (importID == false) return;

    const res = await finish(importID.UpdateID);
    if (res.status != HTTP_STATUS_CODES.OK) {
      // PRINT ERRORs THAT MIGHT HAVE HAPPENED DURING THE IMPORT PROCESS
    }
  }

  return (
    <DashboardContainer title={"Product Import"}>

      <Importer
        chunkSize={10000}
        assumeNoHeaders={false}
        restartable={true}
        onStart={({ file, fields }) => startImport()}
        processChunk={importProducts}
        onComplete={({ file, preview, fields, columnFields }) => finishImport()}>

        <ImporterField name="gtin" label="GTIN/Barcode" />
        <ImporterField name="description" label="Description" />
        <ImporterField name="brand" label="Brand" optional={true} />

      </Importer>


    </DashboardContainer>
  );
};

export default ProductImport;