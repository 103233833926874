import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { HTTP_STATUS_CODES } from '../../api/API';
import { listAccountsApproved, listAccountsToBeApproved, listAccountsWaitingMoreInfo } from '../../api/SignupApproval';
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import RowItem from './RowItem';
import { RowsContainer } from './RowItemStyle';
import { Container, Header, Section } from './Style';

const SignupApproval = () => {

  const [processingAccsToApprove, setProcessingAccsToApprove] = useState(false);
  const [processingAccsApproved, setProcessingAccsApproved] = useState(false);
  const [processingAccsMoreInfo, setProcessingAccsMoreInfo] = useState(false);


  const [accsToApprove, setAccsToApprove] = useState(null);
  const [accsApproved, setAccsApproved] = useState(null);
  const [accsMoreInfo, setAccsMoreInfo] = useState(null);


  const fetchAccsToApprove = async () => {
    await fetchAccountsByType(setProcessingAccsToApprove, setAccsToApprove, listAccountsToBeApproved);
  };

  const fetchAccsApproved = async () => {
    await fetchAccountsByType(setProcessingAccsApproved, setAccsApproved, listAccountsApproved);
  };

  const fetchAccsMoreInfo = async () => {
    await fetchAccountsByType(setProcessingAccsMoreInfo, setAccsMoreInfo, listAccountsWaitingMoreInfo);
  };

  const fetchAccountsByType = async (loadingSetter, accountsSetter, listAccountEndpointCall) => {
    loadingSetter(true);
    const res = await listAccountEndpointCall();
    if (res.status !== HTTP_STATUS_CODES.OK) {
      loadingSetter(false);
      return;
    };
    loadingSetter(false);
    accountsSetter(res.data)
  };

  useEffect(() => {
    fetchAccsToApprove();
    fetchAccsMoreInfo();
    fetchAccsApproved();
  }, []);

  const refreshAccsToApprove = () => fetchAccsToApprove();

  const refreshAccsApproved = () => fetchAccsApproved();

  const refreshAccsMoreInfo = () => fetchAccsMoreInfo();

  return (
    <DashboardContainer title={"Signup Accounts Approval"}>
      <Container>
        <Section column mr bg-color>
          <Header>
            <span>New</span>
          </Header>
          <Section className="request-processing-indicator" column no-border>
            {processingAccsToApprove && (
              <div className="request-processing">
                <span>Processing...</span>
              </div>
            )}
            <div className="body">
              <RowsContainer>
                {accsToApprove?.map((item, index) => (
                  <RowItem refreshCallback={() => {
                    refreshAccsToApprove();  refreshAccsApproved();
                  }} key={index} account={item} />
                ))}
                {accsToApprove?.length === 0 && (
                  <b style={{ padding: '5px' }}>No records</b>
                )}
              </RowsContainer>
            </div>
          </Section>
        </Section>
        <Section column no-border>
          <Section column mb bg-color>
            <Header>
              <span>More Info</span>
            </Header>
            <Section className="request-processing-indicator" column no-border>
              {processingAccsMoreInfo && (
                <div className="request-processing">
                  <span>Processing...</span>
                </div>
              )}
              <div className="body">
                <RowsContainer>
                  {accsMoreInfo?.map((item, index) => (
                    <RowItem refreshCallback={refreshAccsMoreInfo} key={index} account={item} />
                  ))}
                  {accsMoreInfo?.length === 0 && (
                    <b style={{ padding: '5px' }}>No records</b>
                  )}
                </RowsContainer>
              </div>
            </Section>
          </Section>

          <Section column bg-color>
            <Header>
              <span>Recent Applications</span>
            </Header>
            <Section className="request-processing-indicator" column no-border>
              {processingAccsApproved && (
                <div className="request-processing">
                  <span>Processing...</span>
                </div>
              )}
              <div className="body">
                <RowsContainer>
                  {accsApproved?.map((item, index) => (
                    <RowItem refreshCallback={refreshAccsApproved} key={index} account={item} />
                  ))}
                  {accsApproved?.length === 0 && (
                    <b style={{ padding: '5px' }}>No records</b>
                  )}
                </RowsContainer>
              </div>
            </Section>
          </Section>
        </Section>

      </Container>
    </DashboardContainer>
  );

};

export default SignupApproval;