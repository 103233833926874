import React, { useContext, useState } from 'react';
import GVDItem from './GVDItem';
import GVDItemDetail from './GVDItemDetail';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { DetailModel, GroupModel, GVDEditorAPI, VariantModel } from '../GVDEditorAPI';
import { HTTP_STATUS_CODES } from '../../../api/API';

type GVDItemVariantProps = {
  group: GroupModel,
  variant: VariantModel
}

const GVDItemVariant = ({ group, variant }: GVDItemVariantProps) => {

  const { BRICK, refreshBrick } = useContext(ContextSelectedBrick);

  const [details, setDetails] = useState<DetailModel[]>([]);

  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const fetchData = async () => {
    const res = await GVDEditorAPI.getGroupVarDetails(BRICK, group.GroupingID, variant.VariantID);

    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    variant.Total = res.data.length;
    setDetails(res.data);
  };

  const onAddNew = async (description: string) => {
    const res = await GVDEditorAPI.newDetail(description, variant.VariantID);
    if (!res || res.status != HTTP_STATUS_CODES.CREATED) {
      return;
    }
    fetchData();
  };

  const onRename = async (description: string) => {
    const res = await GVDEditorAPI.renameVariant(variant.VariantID, description);
    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    variant.VariantDesc = description
  };

  const onDelete = async () => {
    const res = await GVDEditorAPI.deleteVariant(variant.VariantID);
    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    setHasBeenDeleted(true);
  };

  return hasBeenDeleted ? null : (
    <GVDItem
      description={variant.VariantDesc}
      fetchSubItems={fetchData}
      onAddNew={onAddNew}
      onRename={onRename}
      onDelete={variant.Total > 0 ? false : onDelete}>

      {details.map((detail, key) => <GVDItemDetail key={key} group={group} variant={variant} detail={detail} />)}

    </GVDItem>
  );
};

export default GVDItemVariant;