import React, { useContext, useEffect, useState } from 'react'
import { getUnassignedWords, listIgnoresSubstitutesForWordMatch, listProductsRelatedToWordMatch } from '../../../../api/GroupVariantDetail';
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faListUl } from '@fortawesome/free-solid-svg-icons'
import { SelectedRawWordsContext } from '../context/SelectedRawWordsContext';
import { DashboardPopUpContext } from '../../../../dashboard-components/context/DashboardPopUpContext';
import RawWordsProductList from './RawWordsProductList';
import RawWordsIgnoreSubstitute from './RawWordsIgnoreSubstitute';
import { AutoSizer, List } from 'react-virtualized';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useFormFieldOnChange } from '../../../../utils/FormHooks';

const RawWordsContainer = () => {

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedWords, addWord, removeWord, isWordSelected, clearAndAddWord, clearWords } = useContext(SelectedRawWordsContext);

  const { showPopUp } = useContext(DashboardPopUpContext);

  const [words, setWords] = useState([]);

  const [processing, setProcessing] = useState(false);

  const [rawWordsSearchBox, setRawWordsSearchBox, onChangeRawWordsSearchBox] = useFormFieldOnChange('');

  useEffect(() => {
    fetchRawWords();
  }, [BRICK]);

  const fetchRawWords = async () => {
    if (BRICK == null) return;

    setWords([]);
    setProcessing(true);

    const req = await getUnassignedWords(BRICK, rawWordsSearchBox);
    setProcessing(false);

    if (req.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    setWords(req.data);
  };

  const onSelectWord = (wordIndex) => {
    return () => {
      if (isWordSelected(words[wordIndex])) {
        removeWord(words[wordIndex]);
      } else {
        addWord(words[wordIndex]);
      }
    };
  };

  const listProducts = async (e, wordIndex) => {
    e.stopPropagation();
    const wordMatch = words[wordIndex];

    setProcessing(true);
    const res = await listProductsRelatedToWordMatch(wordMatch.WordMatchID);
    setProcessing(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    showPopUp(<RawWordsProductList prods={res.data} wordMatch={wordMatch} />);
  };

  const showIgnoreSubstitute = async (e, wordIndex) => {
    e.stopPropagation();
    const wordMatch = words[wordIndex];

    setProcessing(true);
    const res = await listIgnoresSubstitutesForWordMatch(wordMatch.WordMatchID);
    setProcessing(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    const onWordMatchUpdate = (wordMatch) => {
      // const currentWordMatches = words;
      // currentWordMatches[wordIndex] = wordMatch;
      // setWords([...currentWordMatches]);
      clearWords();
      fetchRawWords();
    };

    clearAndAddWord(wordMatch);
    showPopUp(
      <RawWordsIgnoreSubstitute
        onWordMatchUpdate={onWordMatchUpdate} wordMatch={wordMatch}
        ignoresSubstitutes={res.data} />
    );
  };

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {

    const isRowSelected = isWordSelected(words[index]);
    return (
      <div key={key} style={style} className={'gvd-word' + (isRowSelected ? ' selected' : '')} onClick={onSelectWord(index)}>
        <input readOnly={true} type="checkbox" checked={isRowSelected} />
        <FontAwesomeIcon icon={faListUl} color="#707070" onClick={(e) => listProducts(e, index)} />
        <div className="gvd-word-description">{words[index].Words.join(' - ')}</div>
        <FontAwesomeIcon icon={faPen} color="#707070" onClick={(e) => showIgnoreSubstitute(e, index)} />
      </div>
    );
  }

  const doRawWordsSearch = (event) => {
    event.preventDefault();
    if (!rawWordsSearchBox.trim().length) {
      return;
    }
    fetchRawWords();
  };

  return (
    <>
      <span className="container-title row">
        <span className="title">Raw Words</span>
        <div className="raw-words-search-container">
          {words.length > 0 && (
            <form onSubmit={doRawWordsSearch}>
              <input onChange={onChangeRawWordsSearchBox} value={rawWordsSearchBox} type="text" placeholder="Search..." />
            </form>
          )}
        </div>
        {selectedWords.length > 0 && <span className="right-note">
          Rows selected: {selectedWords.length}
        </span>}
      </span>
      <div className="gvd-words-list container-body request-processing-indicator" style={{ padding: 0 }}>
        {!processing && words.length == 0 ? (
          <h4>No records</h4>
        ) : null}

        {processing && (
          <div className="request-processing">
            <span>Loading ...</span>
          </div>
        )}

        {words.length > 0 &&
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowHeight={28}
                rowCount={words.length}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        }

      </div>
    </>
  );
};

export default RawWordsContainer;