import React, { useState } from 'react';

import { faAngleRight, faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideMenuItem = ({ children, subItemsLoader = null, isSelected = false }) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const hasSubItems = subItemsLoader !== null ||
    Array.isArray(children) &&
    (typeof children[1].props?.children !== 'string');

  const displayChildren = () => {
    if (hasSubItems && !isExpanded) {
      return null;
    }
    return (children.map && children.map((child, index) => {
      if (index === 0 && hasSubItems) return null;
      return child;
    })) || children;
  };

  const toggleItem = async () => {
    if (subItemsLoader && !hasLoaded) {
      if (isLoading) return;
      setIsLoading(true);
      await subItemsLoader();
      setIsLoading(false);
      setHasLoaded(true);
      setIsExpanded(true);
    } else {
      setIsExpanded(!isExpanded);
    }
  }

  const getItemIcon = () => {
    if (subItemsLoader && isLoading) {
      return faSpinner
    }
    return isExpanded ? faAngleDown : faAngleRight;
  };

  return (
    <div className={"item" + (!hasSubItems && isSelected ? " is-selected" : "")}>
      {hasSubItems && <div className={"item-link" + (isSelected ? " is-selected" : "")}>
        <FontAwesomeIcon
          onClick={toggleItem}
          className={"link-icon-status" + (subItemsLoader && isLoading ? " fa-spin" : "")}
          icon={getItemIcon()} />
        {children[0]}
      </div>}
      {displayChildren()}
    </div>
  );
};

export default SideMenuItem;