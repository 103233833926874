import React, { useContext, useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { DashboardPopUpContext } from "../../dashboard-components/context/DashboardPopUpContext";
import { useFormFieldOnChange } from "../../utils/FormHooks";
import { useLocation } from "react-router-dom";

import styled from "styled-components";
import {
  listBricks,
  listClasses,
  listFamilies,
  listSegments,
  reclassifyProduct,
} from "./API";
const ActionsContainer = styled.div`
  & > button:not(:last-child) {
    margin-right: 5px;
  }
  text-align: center;
`;

const ActionButton = styled.button`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
  font-size: 12px;
  cursor: pointer;
`;

const Dropdown = styled.select`
  padding: 2px;
`;

const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 10px;
  width: 800px;
  height: 200px;

  & div:first-child {
    display: flex;
    flex-direction: row;

    & select {
      flex-grow: 1;
    }
  }

  & span.title {
    margin-right: 5px;
    width: 70px;
    display: inline-block;
    font-weight: bold;
    &::after {
      content: ":";
    }
  }

  & span.description {
    grid-column-start: 1;
    grid-column-end: 2;
    font-size: 14px;
    font-style: italic;
  }
`;

const useDropdownItem = (title, apiFetchCall, defaultValue) => {
  const [dropdownValue, setDropdownValue, onChangeDropdown] =
    useFormFieldOnChange("");
  const [options, setOptions] = useState([]);

  const [_firstFetch, _setFirstFetch] = useState(true);

  const fetchData = async () => {
    const data = await apiFetchCall();
    const hasSelectOption =
      data.filter((item) => item.Desc == "-- SELECT --").length > 0;
    if (!hasSelectOption) {
      data.unshift({ ID: "", Desc: "-- SELECT --" });
    }
    setOptions(data);
    _setFirstFetch((currentState) => {
      if (currentState === true && defaultValue != null) {
        setDropdownValue(defaultValue);
      } else if (data.length > 0) {
        setDropdownValue(data[0].ID);
      }
      return false;
    });
  };

  const Render = () => {
    return (
      <DropdownContainer>
        <div>
          <span className="title">{title}</span>
          <Dropdown
            disabled={options.length == 0}
            onChange={onChangeDropdown}
            value={dropdownValue}
          >
            {options?.map((option, index) => (
              <option key={index} value={option.ID}>
                {option.Desc}
              </option>
            ))}
          </Dropdown>
        </div>
      </DropdownContainer>
    );
  };

  return {
    value: () => dropdownValue,
    reset: () => {
      setDropdownValue("");
      setOptions([]);
    },
    isValueValid: () => new String(dropdownValue).trim().length > 0,
    fetchData,
    Render,
  };
};

const ReclassifyDropdownsModal = ({
  productsToBeUpdated,
  setLoading,
  setErrorMessage,
  refreshCallback,
  children,
}) => {
  const location = useLocation();

  const { hidePopUp } = useContext(DashboardPopUpContext);

  const onClickConfirm = async () => {
    if (!bricks.isValueValid()) {
      setErrorMessage("Please, select a BRICK");
      return;
    }
    setLoading(true);
    await reclassifyProduct(
      productsToBeUpdated.map((product) => product.GTIN),
      bricks.value()
    );
    setLoading(false);
    hidePopUp();
    refreshCallback();
  };

  const loadSegments = async () => {
    const res = await listSegments();
    return res.status !== HTTP_STATUS_CODES.OK ? [] : res.data;
  };

  const loadFamilies = async () => {
    const res = await listFamilies(segments.value());
    return res.status !== HTTP_STATUS_CODES.OK ? [] : res.data;
  };

  const loadClasses = async () => {
    const res = await listClasses(families.value());
    return res.status !== HTTP_STATUS_CODES.OK ? [] : res.data;
  };

  const loadBricks = async () => {
    const res = await listBricks(classes.value());
    return res.status !== HTTP_STATUS_CODES.OK ? [] : res.data;
  };

  const gpc = location.pathname.replace("/ProductsPage/", "").split("/");
  const segments = useDropdownItem(
    "Segment",
    loadSegments,
    gpc[0] && gpc[0].match(/^\d+$/g) ? gpc[0] : null
  );
  const families = useDropdownItem(
    "Family",
    loadFamilies,
    gpc[1] && gpc[1].match(/^\d+$/g) ? gpc[1] : null
  );
  const classes = useDropdownItem(
    "Class",
    loadClasses,
    gpc[2] && gpc[2].match(/^\d+$/g) ? gpc[2] : null
  );
  const bricks = useDropdownItem("Brick", loadBricks);

  useEffect(() => {
    families.reset();
    segments.isValueValid() && families.fetchData();
  }, [segments.value()]);

  useEffect(() => {
    classes.reset();
    segments.isValueValid() && families.isValueValid() && classes.fetchData();
  }, [families.value()]);

  useEffect(() => {
    bricks.reset();
    segments.isValueValid() &&
      families.isValueValid() &&
      classes.isValueValid() &&
      bricks.fetchData();
  }, [classes.value()]);

  useEffect(() => {
    segments.fetchData();
  }, [productsToBeUpdated]);

  return (
    <div>
      <div>
        Products to be{" "}
        <i>
          <b>reclassified</b>
        </i>
        : {productsToBeUpdated?.length}
      </div>
      <br />
      <div>
        {segments.Render()}
        {families.Render()}
        {classes.Render()}
        {bricks.Render()}
      </div>
      {children != null && (
        <div style={{ marginBottom: "10px" }}>{children}</div>
      )}
      <ActionsContainer>
        <ActionButton onClick={() => hidePopUp()}>Cancel</ActionButton>
        <ActionButton onClick={() => onClickConfirm()}>Confirm</ActionButton>
      </ActionsContainer>
    </div>
  );
};

export default ReclassifyDropdownsModal;
