import DashboardContainer from '../../dashboard-components/DashboardContainer';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { listProducts } from './API';
import { useLocation } from 'react-router-dom';

import ReactDataGrid, { filterTypes } from '@inovua/reactdatagrid-enterprise'
import { HTTP_STATUS_CODES } from '../../api/API';
import { DashboardPopUpContext } from '../../dashboard-components/context/DashboardPopUpContext';
import ReclassifyModal from './ReclassifyModal';

import styled from 'styled-components'
import '@inovua/reactdatagrid-enterprise/index.css'
import defaultColumns from './productGridColumns';
import defaultFilterValue from './productGridFilters';
import AssignBrandModal from './AssignBrandModal';

export const PleaseSelectLevel = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
`;

const ProductsPage = () => {
  const location = useLocation();
  const [columns] = useState(defaultColumns);

  const [gpcFilter, setGpcFilter] = useState({
    brickId: 0,
    classId: 0,
    familyId: 0,
    segmentId: 0
  });

  useEffect(() => {
    loadData();
  }, [gpcFilter]);

  const [dataSource, setDataSource] = useState({ dataSource: [] });
  const loadData = () => {
    const fetchData = async ({ skip, limit, sortInfo, groupBy, filterValue }) => {
      skip = Math.round(Math.max(skip - 1, 0) / limit) + 1;
      const res = await listProducts(
        skip, limit, sortInfo, filterValue,
        gpcFilter.segmentId, gpcFilter.familyId, gpcFilter.classId, gpcFilter.brickId, gpcFilter.groupingId, gpcFilter.variantId, gpcFilter.detailId
      );
      if (res.status !== HTTP_STATUS_CODES.OK) {
        return Promise.resolve({ data: [], count: 0 });
      }
      return Promise.resolve({ data: res.data.data, count: res.data.total });
    }
    setDataSource({ dataSource: fetchData });
  };

  useEffect(() => {
    const gpc = location.pathname.replace('/ProductsPage/', '').split('/');
    setGpcFilter({
      detailId: gpc[6] && gpc[6].match(/^\d+$/g) ? gpc[6] : 0,
      variantId: gpc[5] && gpc[5].match(/^\d+$/g) ? gpc[5] : 0,
      groupingId: gpc[4] && gpc[4].match(/^\d+$/g) ? gpc[4] : 0,

      brickId: gpc[3] && gpc[3].match(/^\d+$/g) ? gpc[3] : 0,
      classId: gpc[2] && gpc[2].match(/^\d+$/g) ? gpc[2] : 0,
      familyId: gpc[1] && gpc[1].match(/^\d+$/g) ? gpc[1] : 0,
      segmentId: gpc[0] && gpc[0].match(/^\d+$/g) ? gpc[0] : 0
    });
  }, [location]);

  const RenderSelectionStage = () => {
    const { showPopUp } = useContext(DashboardPopUpContext);

    const [selected, setSelected] = useState({});

    const onSelectionChange = useCallback((selected) => {
      setSelected(selected);
    }, []);

    const areSelectedProductsFromSameSupplier = useMemo(() => {
      const prodsSelected = Object.values(selected?.selected || []);
      let supplierID = prodsSelected[0]?.CompanyID || null;
      for (let i = 1; i < prodsSelected.length; i++) {
        if (prodsSelected[i].CompanyID != supplierID) {
          return false;
        }
      }
      return true;
    }, [selected]);

    if (gpcFilter.segmentId == 0) {
      return <PleaseSelectLevel>Please, select a segment.</PleaseSelectLevel>;
    }
    if (gpcFilter.familyId == 0) {
      return <PleaseSelectLevel>Please, select a family.</PleaseSelectLevel>;
    }
    if (gpcFilter.classId == 0) {
      return <PleaseSelectLevel>Please, select a class.</PleaseSelectLevel>;
    }

    const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
      menuProps.autoDismiss = true
      menuProps.items = [
        { label: 'Product Details' },
        {
          label: 'Reclassify',
          onClick: (event, props, propsIndex) => {
            const prodsSelected = Object.values(selected?.selected || {});
            let products = !prodsSelected.length ? [rowProps.data] : prodsSelected;
            showPopUp(<ReclassifyModal refreshCallback={loadData} productsToBeUpdated={products} />)
          }
        },
        {
          label: 'Assign Brand',
          disabled: !areSelectedProductsFromSameSupplier,
          onClick: (event, props, propsIndex) => {
            const prodsSelected = Object.values(selected?.selected || {});
            let products = !prodsSelected.length ? [rowProps.data] : prodsSelected;
            showPopUp(
              <AssignBrandModal
                companyName={products[0].CompanyName}
                companyID={products[0].CompanyID}
                refreshCallback={loadData}
                productsToBeUpdated={products} />
            )
          }
        }
      ]
    }

    return (
      <ReactDataGrid
        licenseKey={'AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2022-10-27,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=20321614271346880205244607387-2083892569-3379015141489805940'}
        idProperty="ProductID"
        style={{ marginTop: 10, minHeight: 550 }}
        columns={columns}
        defaultFilterValue={defaultFilterValue}
        renderRowContextMenu={renderRowContextMenu}
        onSelectionChange={onSelectionChange}
        filterTypes={filterTypes}
        pagination="remote"
        checkboxColumn={true}
        columnUserSelect={true}
        dataSource={dataSource.dataSource}
      />
    );
  };

  return (
    <DashboardContainer title={"Product List"}>
      <RenderSelectionStage />
    </DashboardContainer>
  );
};

export default ProductsPage;