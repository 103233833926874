import DashboardContainer from '../../dashboard-components/DashboardContainer';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button } from "@mui/material";
import { listProducts } from './API';
import { useLocation } from 'react-router-dom';

import ReactDataGrid, { filterTypes } from '@inovua/reactdatagrid-enterprise'
import { HTTP_STATUS_CODES } from '../../api/API';
import { DashboardPopUpContext } from '../../dashboard-components/context/DashboardPopUpContext';
import ReclassifyModal from './ReclassifyModal';

import styled from 'styled-components'
import '@inovua/reactdatagrid-enterprise/index.css'
import defaultColumns from './productGridColumns';
import defaultFilterValue from './productGridFilters';
import AssignBrandModal from './AssignBrandModal';

export const PleaseSelectLevel = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
`;

const ProductsPage = () => {
  const location = useLocation();
  const [columns] = useState(defaultColumns);
  const [active, setActive] = useState(1)

  const [gpcFilter, setGpcFilter] = useState({
    brickId: 0,
    classId: 0,
    familyId: 0,
    segmentId: 0
  });

  useEffect(() => {
    loadData();
  }, [active]);

  const [dataSource, setDataSource] = useState({ dataSource: [] });

  const loadData = () => {

    const fetchData = async ({ skip, limit, sortInfo, groupBy, filterValue }) => {
      skip = Math.round(Math.max(skip - 1, 0) / limit) + 1;
      const res = await listProducts(
        skip, limit, sortInfo, active, filterValue
      );
      if (res.status !== HTTP_STATUS_CODES.OK) {
        return Promise.resolve({ data: [], count: 0 });
      }
      return Promise.resolve({ data: res.data.data, count: res.data.total });
    }
    setDataSource({ dataSource: fetchData });
  };

  return (
    <DashboardContainer title={"Product List"}>
      <>
      <div className="flex justify-start gap-3 items-center">
        <Button 
        variant="contained"
        onClick={()=>setActive(1)}
        color={active==1?"primary":"disabled"}
        className={`w-full bg-red-200 uppercase !rounded-t-lg !rounded-b-none border border-gray-300 px-4 py-1`}>
              gs1
        </Button>

        <Button variant="contained"
        onClick={()=>setActive(2)}
        color={active==2?"primary":"disabled"}
        className=" w-full !rounded-t-lg !rounded-b-none border border-gray-300 px-4 py-1">
          Categorised
        </Button>

        <Button variant="contained"
        onClick={()=>setActive(3)}
        color={active==3?"primary":"disabled"}
        className=" w-full !rounded-t-lg !rounded-b-none border border-gray-300 px-4 py-1">
          Non-Categorised
        </Button>
    </div>
      <ReactDataGrid
        licenseKey={'AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2022-10-27,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=20321614271346880205244607387-2083892569-3379015141489805940'}
        idProperty="ProductID"
        style={{ marginTop: 10, minHeight: 550 }}
        columns={columns}
        defaultFilterValue={defaultFilterValue}
        filterTypes={filterTypes}
        pagination="remote"
        checkboxColumn={true}
        columnUserSelect={true}
        dataSource={dataSource.dataSource}
        limit={20}
      />
      <div className="bg-black h-px w-full my-4" />
      <div>
        {active==1 &&
          <p>This page shows your Product Data (GS1).</p>
        }
        {active==2 &&
          <p>This page shows your Product Data (Categorised).</p>
        }
        {active==3 &&
          <p>This page shows your Product Data (Non-Categorised).</p>
        }
      </div>
      
      </>
    </DashboardContainer>
  );
};

export default ProductsPage;