import React, { useContext, useEffect, useState } from 'react';
import DashboardPopUp from '../../dashboard-components/DashboardPopUp';

import styled from 'styled-components'
import { useFormFieldOnChange } from '../../utils/FormHooks';
import { assignBrand, listBrands } from './API';
import { HTTP_STATUS_CODES } from '../../api/API';
import { DashboardPopUpContext } from '../../dashboard-components/context/DashboardPopUpContext';

const ActionButton = styled.button`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

const Dropdown = styled.select`
  padding: 2px;
`

const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  & div:first-child {
    display: flex;
    flex-direction: row;

    & select {
      flex-grow: 1;
    }
  }

  & span.title {
    margin-right: 5px;
    width: 70px;
    display: inline-block;
    font-weight: bold;
    &::after {
      content: ':';
    }
  }

  & span.description {
    grid-column-start: 1;
    grid-column-end: 2;
    font-size: 14px;
    font-style: italic;
  }
`

const ErrorMessage = ({ message }) => {
  return message?.trim().length > 0 && (
    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px', marginTop: '5px', marginBottom: '5px', display: 'block' }}>
      {message}
    </span>
  );
};

const AssignBrandModal = ({ companyID, companyName, productsToBeUpdated, refreshCallback }) => {

  const { hidePopUp } = useContext(DashboardPopUpContext);

  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand, onChangeSelectedBrand] = useFormFieldOnChange('');

  useEffect(() => {
    searchBrands();
  }, [companyID]);

  const searchBrands = async () => {
    setErrorMsg('');

    setLoading(true);
    const res = await listBrands(companyID);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) {
      setErrorMsg('An error occurred while processing the request');
      return;
    }
    setBrands(res.data);

    if (!res.data.length) {
      setErrorMsg('No brands available for this supplier');
    }
  };

  const applySelectedBrandToProducts = async () => {
    setErrorMsg('');

    if (!selectedBrand.trim().length) {
      setErrorMsg('Please, select a brand.');
      return;
    }

    setLoading(true);
    const res = await assignBrand(productsToBeUpdated.map((product => product.GTIN)), selectedBrand);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) {
      setErrorMsg('An error occurred while searching for brands');
      return;
    }
    hidePopUp();
    refreshCallback();
  };

  return (
    <DashboardPopUp processing={loading} header={"Assign a new brand"}>
      <div><i><b>Supplier</b></i>: {companyName}</div><br/>
      <div>Products to be <i><b>affected</b></i>: {productsToBeUpdated?.length}</div>
      <DropdownContainer>
        <div>
          <span className="title">Brand</span>
          <Dropdown disabled={!brands.length} onChange={onChangeSelectedBrand} value={selectedBrand}>
            <option value=''>-- SELECT --</option>
            {brands.map((brand, index) => (
              <option key={index} value={brand.BrandID}>{brand.Brand} - {brand.BrandSub}</option>
            ))}
          </Dropdown>
        </div>
      </DropdownContainer>
      <ErrorMessage message={errorMsg} />
      <ActionButton disabled={!brands.length} onClick={applySelectedBrandToProducts}>Confirm</ActionButton>
    </DashboardPopUp>
  );
};

export default AssignBrandModal;