import React from 'react';
import { Link } from 'react-router-dom';
import SideMenuItem from './SideMenuItem';

const SideMenuServiceProvider = () => {

  return (
    <SideMenuItem>
      <Link to="/">Service Provider</Link>
      <SideMenuItem>
        <Link to="/">Home</Link>
        <Link to="/">Analytics</Link>
      </SideMenuItem>
    </SideMenuItem>
  );
};

export default SideMenuServiceProvider;