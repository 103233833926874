import React, { useContext, useState } from 'react';
import { assignGVDToWordMatch, newGVD } from '../../../api/GroupVariantDetail';
import { SelectedBrickContext } from './SelectedBrickContext';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import { DataTableSelectedRowsContext } from '../../components/DataTableSelectedRowsContext';
import { HTTP_STATUS_CODES } from '../../../api/API';

const UnassignedWordsGVDNewForm = ({ onOperationFinishes }) => {

  const [newGroup, , onNewGroupChange] = useFormFieldOnChange('');
  const [newVariant, , onNewVariantChange] = useFormFieldOnChange('');
  const [newDetail, , onNewDetailChange] = useFormFieldOnChange('');

  const [combinationExists, setCombinationExists] = useState(false);

  const { selectedRows } = useContext(DataTableSelectedRowsContext);

  const { selectedBrick } = useContext(SelectedBrickContext);

  const onCreateClick = async () => {
    const newReqRes = await newGVD(selectedBrick, newGroup, newVariant, newDetail);
    if (newReqRes !== false) {
      if (newReqRes.status == HTTP_STATUS_CODES.CONFLICT) {
        setCombinationExists(true);
      }
      else if (selectedRows.length == 0) {
        onOperationFinishes();
      }
      else {
        const assGVDRes = await assignGVDToWordMatch(selectedBrick, newGroup, newVariant, newDetail, selectedRows.map(word => word.WordMatchID));
        if (assGVDRes !== false) {
          onOperationFinishes();
        }
      }
    }
  };

  const onCancelClick = () => {
    onOperationFinishes();
  };

  return (
    <div>
      <div>
        <label htmlFor="newGroup">Group: </label>
        <input id="newGroup" size="15" onChange={onNewGroupChange} value={newGroup} />

    &nbsp;<label htmlFor="newVariant">Variant: </label>
        <input id="newVariant" size="15" onChange={onNewVariantChange} value={newVariant} />

    &nbsp;<label htmlFor="newDetail">Detail: </label>
        <input id="newDetail" size="15" onChange={onNewDetailChange} value={newDetail} />

    &nbsp; <button onClick={onCreateClick}>Create</button>
    &nbsp; <button onClick={onCancelClick}>Cancel</button>
      </div>
      {combinationExists && (
        <div>
          <span style={{ margin: '0px', color: 'red', fontWeight: 'bold', fontSize: '0.8rem' }}>The combination you are trying to create already exists!</span>
        </div>
      )}
    </div>
  );
};

export default UnassignedWordsGVDNewForm;