import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { useFormFieldUtils } from '../../utils/FormHooks';
import { listCountries, updateAccountContactDetails } from '../../api/SignUp';
import { HTTP_STATUS_CODES } from '../../api/API';
import { useNavigate } from 'react-router-dom';
import './GetStartedAccountContactDetails.scss';
import DashboardContainer from '../../dashboard-components/DashboardContainer';

const GetStartedAccountContactDetails = () => {

  const userAuth = useAuthContext();
  const user = userAuth.user;

  const navigate = useNavigate();

  const [secsToNextStep, setSecsToNextStep] = useState(null);
  const [accountUpdated, setAccountUpdated] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const [countries, setCountries] = useState(null);

  const firstNameInput = useFormFieldUtils(user.NameFirst);
  const lastNameInput = useFormFieldUtils(user.NameSurname);
  const mobileNumberInput = useFormFieldUtils('');
  const positionInput = useFormFieldUtils('');

  const companyNameInput = useFormFieldUtils('');
  const phoneNumberInput = useFormFieldUtils('');
  const address1Input = useFormFieldUtils('');
  const address2Input = useFormFieldUtils('');
  const cityInput = useFormFieldUtils('');
  const stateInput = useFormFieldUtils('');
  const postcodeInput = useFormFieldUtils('');
  const countrySelect = useFormFieldUtils('');

  const resetForm = (event) => {
    event.preventDefault();
    [
      firstNameInput, lastNameInput, mobileNumberInput, positionInput,
      companyNameInput, phoneNumberInput, address1Input, address2Input,
      cityInput, stateInput, postcodeInput
    ].forEach(fieldSetFn => {
      fieldSetFn.setValue('');
    });
  };

  const submitForm = (event) => {
    event.preventDefault();

    const fields = [
      firstNameInput, lastNameInput, mobileNumberInput, positionInput,
      companyNameInput, phoneNumberInput, address1Input, cityInput, stateInput, postcodeInput
    ];

    const checkIsValid = (value) => value?.trim().length > 0;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let isValid = checkIsValid(field.value);

      field.setIsFieldValid(true);

      if (!isValid && field == mobileNumberInput && checkIsValid(phoneNumberInput.value)) {
        isValid = true
      } else if (!isValid && field == phoneNumberInput && checkIsValid(mobileNumberInput.value)) {
        isValid = true
      } else {
        isValid = field.checkIsValid(checkIsValid);
      }

      setIsFormValid(isValid);
      if (!isValid) return;
    }
    callSignUpEndPoint();
  };

  const callSignUpEndPoint = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const res = await updateAccountContactDetails({
      NameFirst: firstNameInput.value,
      NameSurname: lastNameInput.value,
      Position: positionInput.value,
      MobileNumber: mobileNumberInput.value,
      CompanyName: companyNameInput.value,
      CompanyPhoneNumber: phoneNumberInput.value,
      CompanyAddress1: address1Input.value,
      CompanyAddress2: address2Input.value,
      CompanyCity: cityInput.value,
      CompanyState: stateInput.value,
      CompanyCountry: countrySelect.value,
      CompanyPostcode: postcodeInput.value,
    });

    setIsSubmitting(false);
    setSubmissionError(res == false || res.status != HTTP_STATUS_CODES.NO_CONTENT);

    if (res !== false && res.status == HTTP_STATUS_CODES.NO_CONTENT) {
      setAccountUpdated(true);
      setSecsToNextStep(3);
      userAuth.setUser({
        ...user,
        hasSignedUp: true,
        isApprovalBeingProcessed: true
      });
    }
  };

  useEffect(() => {
    if (secsToNextStep == null) return;
    if (secsToNextStep <= 0) {
      navigate('/GetStarted/AccountTypeSelection');
      return;
    }
    setTimeout(() => {
      setSecsToNextStep(secsToNextStep - 1);
    }, 1000);
  }, [secsToNextStep]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const res = await listCountries();

    setCountries([]);
    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    setCountries(res.data);
    countrySelect.setValue("036") // Default to Australia
  };

  return (
    <DashboardContainer title={"Contact Details"}>
      <div className="dashboard-get-started">
        <form className="form">
          <table>
            <tr>
              <td><label>First Name</label></td>
              <td><input ref={firstNameInput.ref} className={firstNameInput.isValid ? '' : 'required'} type="text" onChange={firstNameInput.onChange} value={firstNameInput.value} /></td>
            </tr>
            <tr>
              <td><label>Last Name</label></td>
              <td><input ref={lastNameInput.ref} className={lastNameInput.isValid ? '' : 'required'} type="text" onChange={lastNameInput.onChange} value={lastNameInput.value} /></td>
            </tr>
            <tr>
              <td><label>Mobile Number</label></td>
              <td><input ref={mobileNumberInput.ref} className={mobileNumberInput.isValid ? '' : 'required'} type="text" onChange={mobileNumberInput.onChange} value={mobileNumberInput.value} /></td>
            </tr>
            <tr>
              <td><label>Position</label></td>
              <td><input ref={positionInput.ref} className={positionInput.isValid ? '' : 'required'} type="text" onChange={positionInput.onChange} value={positionInput.value} /></td>
            </tr>
            <tr>
              <td><label>Company Name</label></td>
              <td><input ref={companyNameInput.ref} className={companyNameInput.isValid ? '' : 'required'} type="text" onChange={companyNameInput.onChange} value={companyNameInput.value} /></td>
            </tr>
            <tr>
              <td><label>Company Phone Number</label></td>
              <td><input ref={phoneNumberInput.ref} className={phoneNumberInput.isValid ? '' : 'required'} type="text" onChange={phoneNumberInput.onChange} value={phoneNumberInput.value} /></td>
            </tr>
            <tr>
              <td><label>Company Address 1</label></td>
              <td><input ref={address1Input.ref} className={address1Input.isValid ? '' : 'required'} type="text" onChange={address1Input.onChange} value={address1Input.value} /></td>
            </tr>
            <tr>
              <td><label>Company Address 2</label></td>
              <td><input ref={address2Input.ref} type="text" onChange={address2Input.onChange} value={address2Input.value} /></td>
            </tr>
            <tr>
              <td><label>Postcode</label></td>
              <td><input ref={postcodeInput.ref} type="text" onChange={postcodeInput.onChange} value={postcodeInput.value} /></td>
            </tr>
            <tr>
              <td><label>City</label></td>
              <td><input ref={cityInput.ref} className={cityInput.isValid ? '' : 'required'} type="text" onChange={cityInput.onChange} value={cityInput.value} /></td>
            </tr>
            <tr>
              <td><label>State</label></td>
              <td><input ref={stateInput.ref} className={stateInput.isValid ? '' : 'required'} type="text" onChange={stateInput.onChange} value={stateInput.value} /></td>
            </tr>
            <tr>
              <td><label>Country</label></td>
              <td>
                {countries == null && (
                  <span style={{ fontStyle: ' italic', fontWeight: 'bold', fontSize: '15px' }}>Loading countries ...</span>
                )}
                {countries !== null && (
                  <select value={countrySelect.value} onChange={countrySelect.onChange}>
                    {countries.map((country, index) => (
                      <option key={index} value={country.CountryCode}>{country.CountryName ?? 'NULL'}</option>
                    ))}
                  </select>
                )}
              </td>
            </tr>
            {!isFormValid && <tr>
              <td colSpan="2" className="form-invalid">
                Please, check the fields in red and provide valid information
              </td>
            </tr>}
            {isSubmitting && <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
                We are processing your request ...
              </td>
            </tr>}
            {submissionError && <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: 'red' }}>
                Something went wrong, please contact our support!
              </td>
            </tr>}
            {accountUpdated && <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
                All set up! You will be taken back to the dashboard in {secsToNextStep} second(s)
              </td>
            </tr>}
            {countries != null && !isSubmitting && !accountUpdated && (
              <tr>
                <td colSpan="2" className="action-footer">
                  <button onClick={submitForm}>Confirm</button>
                  <button onClick={resetForm}>Reset</button>
                </td>
              </tr>
            )}
          </table>
        </form>
      </div>
    </DashboardContainer>
  );
};

export default GetStartedAccountContactDetails;