const getUser = () => JSON.parse(localStorage.getItem('user'));

const setUser = (user) => {
  if (user === null) return;
  localStorage.setItem('user', JSON.stringify(user));
};

const getToken = () => {
  const token = localStorage.getItem('token');
  if (token == null) return null;
  return JSON.parse(token).access_token;
};

const setToken = (token) => {
  if (token === null) return;
  localStorage.setItem('token', JSON.stringify(token));
};

const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

const isLoggedIn = () => getToken() != null;

export {
  getUser, setUser, getToken, setToken, isLoggedIn, logoutUser
};