import { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { MenuGPC } from "../../api/MenuGPC";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SideMenuItem from "../SideMenuItem";
import React from "react";
import FamilyItem from "./FamilyItem";

const SegmentItem = ({ segment }) => {

  const location = useLocation();

  const [families, setFamilies] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const loadFamilies = async (segmentId) => {
    const res = await MenuGPC.getFamilies(segmentId);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setFamilies(res.data);
  };

  useEffect(() => {
    const gpc = window.location.pathname.replace('/ProductsPage/', '').split('/');
    if (!gpc[0] || !gpc[0].match(/^\d+$/g)) {
      setIsSelected(false);
    } else {
      setIsSelected(gpc[0] == segment.ID);
    }
  }, [location]);

  return (
    <SideMenuItem subItemsLoader={async () => await loadFamilies(segment.ID)} isSelected={isSelected}>
      <Link to={"/ProductsPage/" + segment.ID}>
        <span title={segment.ID + " : " + segment.Code + " : " + segment.Desc}>{segment.Desc ?? 'NOT DEFINED'}</span>
      </Link>
      {families.map((family, index) => <FamilyItem key={index} segment={segment} family={family} />)}
    </SideMenuItem>
  );
}

export default SegmentItem;