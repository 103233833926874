import React, { useContext, useState } from 'react';
import DashboardPopUp from '../../../dashboard-components/DashboardPopUp';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';

import styled from 'styled-components'
const Button = styled.button`
  padding: 5px;
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

type GVDItemDeleteModalProps = {
  onClickYes: () => Promise<void>,
  onClickNo?: () => Promise<void>
}

const GVDItemDeleteModal = ({ onClickYes, onClickNo }: GVDItemDeleteModalProps) => {

  const { hidePopUp } = useContext(DashboardPopUpContext);

  const [loading, setLoading] = useState(false);

  const callYesClick = async () => {
    setLoading(true);
    await onClickYes();
    setLoading(false);
    hidePopUp();
  }

  const callNoClick = async () => {
    onClickNo && await onClickNo();
    hidePopUp();
  }

  return (
    <DashboardPopUp processing={loading} header={"Are you sure you want to proceed with the exclusion?"}>
      <div style={{ textAlign: 'center' }}>
        <Button style={{ marginRight: '5px' }} onClick={callYesClick}>Yes</Button>
        <Button style={{ marginLeft: '5px' }} onClick={callNoClick}>No</Button>
      </div>
    </DashboardPopUp>
  );
};

export default GVDItemDeleteModal;