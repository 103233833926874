import React, { useContext, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { getGroupVars } from '../../../../api/GroupVariantDetail';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItem from './GVDItem';
import GVDItemVariant from './GVDItemVariant';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';

const GVDItemGroup = ({ group }) => {

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail, setSelectedDetail } = useContext(SelectedGVDDetailContext);

  const [variants, setVariants] = useState([]);

  const fetchData = async () => {
    const res = await getGroupVars(BRICK, group.GroupingID);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    setVariants(res.data);
  };

  const isSelected = () => selectedDetail.GroupingID == group.GroupingID;

  const onClick = () => {
    setSelectedDetail({
      GroupingID: group.GroupingID, GroupingDesc: group.GroupingDesc,
      VariantID: '', VariantDesc: '',
      DetailID: '', DetailDesc: ''
    })
  };

  return (
    <GVDItem
      description={group.GroupingDesc}
      total={group.Total}
      onClick={onClick}
      isSelected={isSelected}
      fetchData={fetchData}>

      {variants.map((variant, key) => (
        <GVDItemVariant key={key} group={group} variant={variant} />
      ))}

    </GVDItem>
  );
};

export default GVDItemGroup;