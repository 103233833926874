import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const listSegments = async () => {
  try {
    return await getAuthRequest(getToken()).get('supplier/products/listSegments');
  } catch (error) {
    return false;
  }
};

const listFamilies = async (segment) => {
  try {
    return await getAuthRequest(getToken()).get('supplier/products/listFamilies/' + segment);
  } catch (error) {
    return false;
  }
};

const listClasses = async (segment, family) => {
  try {
    return await getAuthRequest(getToken()).get(`supplier/products/listClasses/${segment}/${family}`);
  } catch (error) {
    return false;
  }
};

const listBricks = async (segment, family, brickClass) => {
  try {
    return await getAuthRequest(getToken()).get(`supplier/products/listBricks/${segment}/${family}/${brickClass}`);
  } catch (error) {
    return false;
  }
};

const listGroups = async (Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listGroups`, {
      Segment, Family, Class, Brick
    });
  } catch (error) {
    return false;
  }
};

const listGroupVars = async (Group, Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listGroupVars`, {
      Group, Segment, Family, Class, Brick
    });
  } catch (error) {
    return false;
  }
};

const listGroupVarDetails = async (Group, Variant, Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listGroupVarDetails`, {
      Group, Variant, Segment, Family, Class, Brick
    });
  } catch (error) {
    return false;
  }
};

const listProducts = async (GVD, Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listProducts`, { GVD, Segment, Family, Class, Brick });
  } catch (error) {
    return false;
  }
};

const listProductsUnassigned = async (Segment, Family, Class, Brick) => {
  try {
    return await getAuthRequest(getToken()).post(`supplier/products/listProductsUnassigned`, { Segment, Family, Class, Brick });
  } catch (error) {
    return false;
  }
};

export { listSegments, listFamilies, listClasses, listBricks, listGroups, listGroupVars, listGroupVarDetails, listProducts, listProductsUnassigned };