import React, { useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Auth } from "../../api/Auth";
import { useNavigate } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";

import "./ForgotPassword.css";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [succeeded, setSucceeded] = useState(false);
  const [failed, setFailed] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");

  const onEmailInputChange = (e) => {
    onEmailChange(e);
    hideStatusMessages();
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (processing || emailInput.trim().length == "") {
      return;
    }

    hideStatusMessages();

    setProcessing(true);
    const reqRes = await Auth().ForgotPassword(emailInput);
    setProcessing(false);

    if (reqRes == false || reqRes.status != HTTP_STATUS_CODES.OK) {
      setFailed(true);
      return;
    }

    setSucceeded(true);
  };

  const hideStatusMessages = () => {
    setProcessing(false);
    setFailed(false);
    setSucceeded(false);
  };

  const passwordResetLinkSent = () => {
    return (
      <p style={{ fontWeight: "normal" }}>
        Check your email and follow the instructions to reset your password.
      </p>
    );
  };

  const formContent = () => {
    return (
      <>
        <div className="FormFieldRow">
          <label>
            <small>
              <i>
                <b>Enter your email below</b>
              </i>
            </small>
          </label>
          <input
            type="email"
            placeholder="Enter your email..."
            value={emailInput}
            onChange={onEmailInputChange}
          />
        </div>
        {failed && (
          <div className="FormFieldRow Error">
            An error has occurred while processing the password reset request.
          </div>
        )}
        <div className="FormButtonContainer">
          <button disabled={processing} onClick={submitForm}>
            Reset password
          </button>
          <button
            disabled={processing}
            onClick={(e) => {
              e.preventDefault();
              navigate("/SignIn");
            }}
          >
            Go to Login
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="SignUpPageContainer">
      <form>
        <img
          style={{ width: "200px", marginBottom: "1rem", alignSelf: "center" }}
          src="BBA-Logo-TBl-S.png"
          alt=""
        />
        <p>
          {!succeeded
            ? "Forgot you password?"
            : "We've sent you a password reset link"}
        </p>
        {!succeeded ? formContent() : passwordResetLinkSent()}
      </form>
    </div>
  );
};

export default ForgotPassword;
