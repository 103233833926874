import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HTTP_STATUS_CODES } from '../api/API';
import { MenuGPC } from '../api/MenuGPC';
import SegmentItem from './SideMenuGPC/SegmentItem';
import SideMenuItem from './SideMenuItem';

const SideMenuGPC = () => {

  const [segments, setSegments] = useState([]);

  const loadSegments = async () => {
    const res = await MenuGPC.getSegments();
    if (res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setSegments(res.data);
  };

  return (
    <SideMenuItem subItemsLoader={loadSegments}>
      <Link to="/ProductsPage">Products</Link>
      {segments.map((segment, index) => <SegmentItem key={index} segment={segment} />)}
    </SideMenuItem>
  );
};

export default SideMenuGPC;
