import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'

const GVDItem = ({ description, total, children, hasChildren, fetchData, level = 0 }) => {

  const [hasRequestedData, setHasRequestedData] = useState(false);
  const [showItemSub, setShowItemSub] = useState(false);

  const toggleSubItem = () => {
    const show = !showItemSub;
    setShowItemSub(show);

    if (hasChildren && !hasRequestedData) {
      requestData();
    }
  };

  const requestData = async () => {
    await fetchData();
    setHasRequestedData(true);
  };

  const statusIcon = () => !children ? null : <FontAwesomeIcon icon={showItemSub ? faAngleDown : faAngleRight} />;

  const style = {
    marginLeft: (level * 15) + 'px'
  }

  return (
    <>
      <div className="gvd-item" style={style}>
        <span className={"description"} onClick={toggleSubItem}>
          {statusIcon()} {description ?? 'Not Defined'}
          {total != null && total >= 0 && <span className="total"> ({total})</span>}
        </span>
      </div>
      {showItemSub && <>{children}</>}
    </>
  );
};

export default GVDItem;