import React, { useContext, useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../../api/API";
import {
  createParentCompany,
  searchParentCompanyByName,
} from "../../../api/BarcodeScan";
import { listCountries } from "../../../api/SignUp";
import { DashboardPopUpContext } from "../../../dashboard-components/context/DashboardPopUpContext";
import DashboardPopUp from "../../../dashboard-components/DashboardPopUp";
import { useFormFieldOnChange } from "../../../utils/FormHooks";
import { BarcodeInput, BarcodeSearchButton } from "./ProductScanNewStyle";

const ParentCompanyModal = ({
  assignToCallbackParam,
  assingToCallBack,
  refreshCallback,
}) => {
  const { hidePopUp } = useContext(DashboardPopUpContext);

  const [companyName, setCompanyName, onChangeCompanyName] =
    useFormFieldOnChange("");

  const [newName, setNewName, onChangeNewName] = useFormFieldOnChange("");
  const [newPhone, setNewPhone, onChangeNewPhone] = useFormFieldOnChange("");
  const [newEmail, setNewEmail, onChangeNewEmail] = useFormFieldOnChange("");
  const [newWebsite, setNewWebsite, onChangeNewWebsite] =
    useFormFieldOnChange("");
  const [newAddress1, setNewAddress1, onChangeAddress1] =
    useFormFieldOnChange("");
  const [newAddress2, setNewAddress2, onChangeAddress2] =
    useFormFieldOnChange("");
  const [newCity, setNewCity, onChangeCity] = useFormFieldOnChange("");
  const [newState, setNewState, onChangeState] = useFormFieldOnChange("");
  const [newPostalCode, setNewPostalCode, onChangePostalCode] =
    useFormFieldOnChange("");
  const [newCountry, setNewCountry, onChangeNewCountry] =
    useFormFieldOnChange("");

  const [countries, setCountries] = useState(null);

  const [showAddNewCompanyForm, setShowAddNewCompanyForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [addNewErrorMsg, setNewErrorMsg] = useState(null);

  const searchCompanies = async (e) => {
    e.preventDefault();

    if (companyName.trim().length == 0 || loading == true) return;

    setErrorMsg(null);
    setSelectedCompany(null);

    setLoading(true);

    const res = await searchParentCompanyByName(companyName);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setShowAddNewCompanyForm(true);

    if (res.data.length === 0) {
      setErrorMsg("No companies found");
    }

    setCompanies(res.data);
  };

  const confirmClick = () => {
    setErrorMsg(null);
    if (selectedCompany === null) {
      setErrorMsg("Please select a company");
      return;
    }
    // grabbing of the id
    let companyID = selectedCompany?.CompanyID;
    assignCompanyTo(companyID, setErrorMsg);
  };

  const addNewClick = async () => {
    setNewErrorMsg(null);

    if (!newName.trim().length) {
      setNewErrorMsg("Please provide a name for the company");
      return;
    }

    setLoading(true);
    // the create company function recieves all the parameterst to create a new company
    const createCompany = await createParentCompany(
      newName,
      newPhone,
      newEmail,
      newWebsite,
      newCountry,
      newAddress1,
      newAddress2,
      newCity,
      newState,
      newPostalCode
    );
    if (createCompany.status !== HTTP_STATUS_CODES.OK) {
      setNewErrorMsg("An occurred while creating the parent company");
      setLoading(false);
      return;
    }

    assignCompanyTo(createCompany.data.CompanyID, setNewErrorMsg);
  };

  const assignCompanyTo = async (companyID, errorMsgSetter) => {
    setLoading(true);
    const res = await assingToCallBack(companyID, assignToCallbackParam);
    setLoading(false);

    if (res.status === HTTP_STATUS_CODES.OK) {
      hidePopUp();
      refreshCallback();
    } else {
      errorMsgSetter("An occurred while assigning the record");
    }
  };

  const selectCompany = (company) => {
    setErrorMsg(null);
    setSelectedCompany(company);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    setLoading(true);
    const res = await listCountries();
    setLoading(false);

    setCountries([]);
    if (res.status != HTTP_STATUS_CODES.OK) {
      setNewErrorMsg("An occurred while fetching countries");
      return;
    }

    setCountries(res.data);
  };

  return (
    <DashboardPopUp processing={loading} header={"Search for parent companies"}>
      <form style={{ marginBottom: "5px" }} onSubmit={searchCompanies}>
        {/* styled input component that is used in rendering input fields */}
        <BarcodeInput
          autoFocus
          onChange={onChangeCompanyName}
          value={companyName}
          type="text"
          placeholder="Enter the company name"
        />
        &nbsp;
        <BarcodeSearchButton
          disabled={loading == true}
          onClick={searchCompanies}
        >
          Search
        </BarcodeSearchButton>
      </form>
      {errorMsg !== null && (
        <div style={{ color: "red", marginBottom: "5px" }}>{errorMsg}</div>
      )}
      {/* this is to check if the companies data is empty so as to render 
      the search results field */}
      {companies !== null ? (
        <div
          style={{
            // border: "1px solid green",
            overflow: "auto",
            height: 150,
            boxSizing: "border-box",
            paddingBottom: "20px",
            width: 400,
          }}
        >
          {companies?.map((comp, index) => (
            <div
              key={index}
              style={{
                marginBottom: "5px",
                // width: "20%",
                // backgroundColor: "red",
                // // height: 20,
                // overflow: "hidden",
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                onChange={() => {
                  selectCompany(comp);
                }}
                checked={selectedCompany?.CompanyID == comp.CompanyID}
              />
              <span>{comp.CompanyName}</span>
              {comp.EntityGLN !== null ? " - " + comp.EntityGLN : ""}
            </div>
          ))}
        </div>
      ) : null}
      <BarcodeSearchButton
        onClick={confirmClick}
        style={{ marginBottom: "5px" }}
        disabled={loading == true}
      >
        Confirm
      </BarcodeSearchButton>
      <hr />
      <b style={{ paddingBottom: "5px", paddingTop: "10px", display: "block" }}>
        Add new company
      </b>
      {addNewErrorMsg !== null && (
        <div style={{ color: "red", marginBottom: "5px" }}>
          {addNewErrorMsg}
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeNewName}
          value={newName}
          type="text"
          placeholder="Name"
        />

        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeNewPhone}
          value={newPhone}
          type="text"
          placeholder="Phone"
        />

        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeNewEmail}
          value={newEmail}
          type="text"
          placeholder="Email"
        />

        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeNewWebsite}
          value={newWebsite}
          type="text"
          placeholder="Website"
        />
        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeAddress1}
          value={newAddress1}
          type="text"
          placeholder="Address Street 1"
        />
        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeAddress2}
          value={newAddress2}
          type="text"
          placeholder="Address Street 2"
        />
        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeCity}
          value={newCity}
          type="text"
          placeholder="Address City"
        />
        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangePostalCode}
          value={newPostalCode}
          type="text"
          placeholder="Address Postcode"
        />
        <BarcodeInput
          style={{ marginBottom: "5px", width: "100%" }}
          disabled={!showAddNewCompanyForm}
          onChange={onChangeState}
          value={newState}
          type="text"
          placeholder="Address State"
        />

        {countries !== null && (
          <select
            style={{ marginBottom: "5px", padding: "5px" }}
            disabled={!showAddNewCompanyForm}
            value={newCountry}
            onChange={onChangeNewCountry}
          >
            <option value="">-- SELECT --</option>
            {countries.map((country, index) => (
              <option key={index} value={country.CountryCode}>
                {country.CountryName ?? "NOT DEFINED"}
              </option>
            ))}
          </select>
        )}
      </div>
      <BarcodeSearchButton
        style={{ cursor: !showAddNewCompanyForm ? "auto" : "pointer" }}
        disabled={!showAddNewCompanyForm}
        onClick={() => addNewClick()}
      >
        Add new
      </BarcodeSearchButton>
    </DashboardPopUp>
  );
};

export default ParentCompanyModal;
