import { isValid } from "gtin";
import React, { useState } from "react";
import { scanProduct } from "../../../api/BarcodeScan";
import DashboardContainer from "../../../dashboard-components/DashboardContainer";
import { useFormFieldUtils } from "../../../utils/FormHooks";
import ButtonShowCompromisedModal from "./ButtonShowCompromisedModal";
import ButtonShowParentModal from "./ButtonShowParentModal";
import PrefixClickable from "./ButtonShowPrefixModal";
import ButtonShowReclassifyModal from "./ButtonShowReclassifyModal";

import {
  BarcodeInput,
  BarcodeSearchButton,
  BottomSection,
  Card,
  DetailTable,
  ImageLogo,
  MiddleSection,
  ProductScanContainer,
  UpperSection,
} from "./ProductScanNewStyle.js";

const ProductScanNew = () => {
  const isValidGTIN = (GTIN) => {
    try {
      return isValid(GTIN);
    } catch (error) {
      return false;
    }
  };

  const [apiRes, setApiRes] = useState(null);
  const GTINInput = useFormFieldUtils("");
  const [validGTIN, setValidGTIN] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const searchProduct = (event) => {
    event.preventDefault();

    if (GTINInput.value.trim().length == 0) return;

    if (!isValidGTIN(GTINInput.value.trim())) {
      setValidGTIN(false);
      return;
    }

    setValidGTIN(true);
    callEndpoint(GTINInput.value.trim());
  };

  const callEndpoint = async (gtin) => {
    if (isLoading) return;
    setApiRes(null);
    setIsLoading(true);
    const apiRes = await scanProduct(gtin);
    setIsLoading(false);
    if (apiRes == false) return;
    setApiRes(apiRes.data);
    GTINInput.setValue("");
    GTINInput.focus();
  };

  const refreshPage = () => {
    callEndpoint(apiRes?.ProductInfo?.GTIN);
  };

  const isNewProduct = () => {
    if (apiRes === null) return {};
    if (apiRes?.ProductInfo?.isNew === true) {
      return { "new-record": true };
    }
    return { "existing-record": true };
  };

  const isNewSupplier = () => {
    if (apiRes === null) return {};
    if (apiRes?.SupplierInfo?.isNew === true) {
      return { "new-record": true };
    }
    return { "existing-record": true };
  };

  const isNewPrefix = () => {
    if (apiRes === null) return {};
    if (apiRes?.PrefixesInfo?.JustAdded.length > 0) {
      return { "new-record": true };
    }
    return { "existing-record": true };
  };

  return (
    <>
      <DashboardContainer title={"Product Scan"}>
        <ProductScanContainer className="request-processing-indicator">
          {isLoading && (
            <div className="request-processing">
              <span>Loading ...</span>
            </div>
          )}

          <UpperSection>
            <div className="images">
              <ImageLogo
                className={
                  apiRes?.ProductInfo?.BrandImgUrl == null ? "" : "bg-white"
                }
              >
                <span className="title">Brand</span>
                <img src={apiRes?.ProductInfo?.BrandImgUrl} />
              </ImageLogo>

              <ImageLogo
                className={
                  apiRes?.ProductInfo?.ProductImgUrl == null ? "" : "bg-white"
                }
              >
                <span className="title">Product Image</span>
                <img src={apiRes?.ProductInfo?.ProductImgUrl} />
              </ImageLogo>
            </div>
            <div className="search-area">
              <DetailTable style={{ margin: "0 auto", width: "500px" }}>
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <form onSubmit={searchProduct}>
                        <BarcodeInput
                          autoFocus
                          ref={GTINInput.ref}
                          value={GTINInput.value}
                          onChange={GTINInput.onChange}
                          placeholder="Enter the Barcode/GTIN"
                          type="text"
                        />
                        <BarcodeSearchButton>Search</BarcodeSearchButton>
                      </form>
                    </td>
                  </tr>
                  {apiRes?.ErrorMessages.length === 0 && (
                    <>
                      <tr>
                        <td className="label">GTIN</td>
                        <td>
                          {apiRes.ProductInfo.GTIN} (ID: {apiRes.ProductInfo.ID}
                          )
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Brand</td>
                        <td>{apiRes.ProductInfo.Brand}</td>
                      </tr>
                      <tr>
                        <td className="label">Description</td>
                        <td>{apiRes.ProductInfo.Name}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <ButtonShowCompromisedModal
                            refreshCallback={refreshPage}
                            GTIN={apiRes?.ProductInfo?.GTIN}
                          />
                          &nbsp;
                          <ButtonShowReclassifyModal
                            productInfo={apiRes?.ProductInfo}
                            refreshCallback={refreshPage}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  {!validGTIN && (
                    <tr>
                      <td colSpan="2" style={{ color: "red" }}>
                        Please, enter a valid barcode
                      </td>
                    </tr>
                  )}
                  {apiRes?.ErrorMessages.map((error, index) => (
                    <tr key={index}>
                      <td colSpan="2" style={{ color: "red" }}>
                        ERROR: {error}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </DetailTable>
            </div>
          </UpperSection>
          <MiddleSection>
            <Card {...isNewSupplier()}>
              <span className="title">Supplier</span>
              <div className="body">
                {apiRes !== null && (
                  <DetailTable>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Name</td>
                        <td>{apiRes.SupplierInfo?.Name}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Address</td>
                        <td>
                          {apiRes.SupplierInfo?.Address1 ?? "NOT DEFINED"} -{" "}
                          {apiRes.SupplierInfo?.Postcode ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          {apiRes.SupplierInfo?.City ?? "NOT DEFINED"} -{" "}
                          {apiRes.SupplierInfo?.State ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Ownership</td>
                        <td>
                          {apiRes.SupplierInfo?.Ownership ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Entity GLN</td>
                        <td>
                          {apiRes.SupplierInfo?.EntityGLN ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Tax</td>
                        <td>{apiRes.SupplierInfo?.Tax ?? "NOT DEFINED"}</td>
                      </tr>
                      {apiRes.SupplierParent?.ID == null && (
                        <tr>
                          <td colSpan="2">
                            <ButtonShowParentModal
                              refreshCallback={refreshPage}
                              CompanyID={apiRes.SupplierInfo?.ID}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </DetailTable>
                )}
                {apiRes?.SupplierParent?.ID != null && (
                  <DetailTable
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <i>
                            <b>Parent</b>
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Name</td>
                        <td>{apiRes.SupplierParent?.Name}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Address</td>
                        <td>
                          {apiRes.SupplierParent?.Address1 ?? "NOT DEFINED"} -{" "}
                          {apiRes.SupplierParent?.Postcode ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          {apiRes.SupplierParent?.City ?? "NOT DEFINED"} -{" "}
                          {apiRes.SupplierParent?.State ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Ownership</td>
                        <td>
                          {apiRes.SupplierParent?.Ownership ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Entity GLN</td>
                        <td>
                          {apiRes.SupplierParent?.EntityGLN ?? "NOT DEFINED"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Tax</td>
                        <td>{apiRes.SupplierParent?.Tax ?? "NOT DEFINED"}</td>
                      </tr>
                    </tbody>
                  </DetailTable>
                )}
              </div>
            </Card>
            <Card {...isNewPrefix()}>
              <span className="title">Prefixes</span>
              <div className="body">
                {apiRes?.PrefixesInfo?.JustAdded.length > 0 && (
                  <DetailTable>
                    <tbody>
                      {apiRes.PrefixesInfo.JustAdded.map((prefix, key) => (
                        <tr key={key}>
                          <td style={{ fontWeight: "bold" }}>
                            {key == 0 ? "New" : null}
                          </td>
                          <td>{prefix}</td>
                        </tr>
                      ))}
                    </tbody>
                  </DetailTable>
                )}
                {apiRes?.PrefixesInfo?.Existing.length > 0 && (
                  <DetailTable>
                    <tbody>
                      {apiRes.PrefixesInfo.Existing.map((prefix, key) => (
                        <tr key={key}>
                          <td style={{ fontWeight: "bold" }}>
                            {key == 0 ? "Existing" : null}
                          </td>
                          <PrefixClickable prefix={prefix} />
                        </tr>
                      ))}
                    </tbody>
                  </DetailTable>
                )}
              </div>
            </Card>
            <Card {...isNewProduct()}>
              <span className="title">Product</span>
              <div className="body">
                {apiRes !== null && (
                  <DetailTable>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Source</td>
                        <td>{apiRes.ProductInfo?.Source}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Origin</td>
                        <td>{apiRes.ProductInfo?.Origin}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Percentage</td>
                        <td>{apiRes.ProductInfo?.Percentage}</td>
                      </tr>
                    </tbody>
                  </DetailTable>
                )}
              </div>
            </Card>
          </MiddleSection>
          <BottomSection>
            <Card>
              <span className="title no-border">
                Segment
                {apiRes !== null && (
                  <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
                    {apiRes.BrickInfo?.Segment ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.SegmentDesc ?? "NOT DEFINED"}
                  </span>
                )}
              </span>
            </Card>
            <Card>
              <span className="title no-border">
                Family
                {apiRes !== null && (
                  <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
                    {apiRes.BrickInfo?.Family ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.FamilyDesc ?? "NOT DEFINED"}
                  </span>
                )}
              </span>
            </Card>
            <Card>
              <span className="title no-border">
                Class
                {apiRes !== null && (
                  <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
                    {apiRes.BrickInfo?.Class ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.ClassDesc ?? "NOT DEFINED"}
                  </span>
                )}
              </span>
            </Card>
          </BottomSection>
          <BottomSection>
            <Card>
              <span className="title">Brick</span>
              <div className="body center">
                {apiRes !== null && (
                  <>
                    {apiRes.BrickInfo?.Brick ?? "NOT DEFINED"} -{" "}
                    {apiRes.BrickInfo?.BrickDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </Card>
            <Card>
              <span className="title">Grouping</span>
              <div className="body center">
                {apiRes !== null && (
                  <>
                    {apiRes.GroupVariantDetail?.Group ?? "NOT DEFINED"} -{" "}
                    {apiRes.GroupVariantDetail?.GroupDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </Card>
            <Card>
              <span className="title">Variant</span>
              <div className="body center">
                {apiRes !== null && (
                  <>
                    {apiRes.GroupVariantDetail?.Variant ?? "NOT DEFINED"} -{" "}
                    {apiRes.GroupVariantDetail?.VariantDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </Card>
            <Card>
              <span className="title">Detail</span>
              <div className="body center">
                {apiRes !== null && (
                  <>
                    {apiRes.GroupVariantDetail?.Detail ?? "NOT DEFINED"} -{" "}
                    {apiRes?.GroupVariantDetail?.DetailDesc ?? "NOT DEFINED"}
                  </>
                )}
              </div>
            </Card>
          </BottomSection>
        </ProductScanContainer>
      </DashboardContainer>
    </>
  );
};

export default ProductScanNew;
