import React from 'react';
import ModalAction from '../../../components/PopupDialog/ModalAction';
import ModalActions from '../../../components/PopupDialog/ModalActions';
import ModalDialog from '../../../components/PopupDialog/ModalDialog';
import ModalHeader from '../../../components/PopupDialog/ModalDialogHeader';

const BrandActionNotValidatedRecordDialog = ({
  brandToBeChanged,
  showModal,
  onClose,
  onParentBrandInputChange,
  existingValidatedBrands,
  onNewRecordBrandChange,
  onNewRecordBrandSubChange,
  newRecodAlreadyExists,
  confirmChange
}) => {

  brandToBeChanged = brandToBeChanged == null ? {} : brandToBeChanged;

  return (
    <ModalDialog showModal={showModal} onClose={onClose}>
      <ModalHeader header="Select a Brand from the list below or create a new record" />

      <table className="CreateNewBrandContainer" style={{
        marginBottom: '30px'
      }}>
        <thead>
          <tr>
            <td colSpan="2">
              <b>Modifying</b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Brand ID:</td>
            <td>{brandToBeChanged.BrandID}</td>
          </tr>
          <tr>
            <td>Brand:</td>
            <td>{brandToBeChanged.Brand}</td>
          </tr>
          <tr>
            <td>Sub Brand:</td>
            <td>{brandToBeChanged.BrandSub}</td>
          </tr>
        </tbody>
      </table>
      <label htmlFor="parent_brand_inpt">Parent Brand:</label>
      <select onChange={onParentBrandInputChange} id="parent_brand_inpt">
        <option>-- Select a Brand --</option>
        {existingValidatedBrands.map(item => (
          <option value={item.BrandID} key={item.BrandID}>{item.Brand + ' - ' + item.BrandSub}</option>
        ))}
      </select>
      <span style={{
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'block',
        marginTop: '20px'
      }}><i>OR</i></span>
      <div className="CreateNewBrandContainer">
        <span><u>Create new</u></span>
        <table>
          <thead>
            <tr>
              <td>Brand</td>
              <td>Sub Brand</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input onChange={onNewRecordBrandChange} type="text" placeholder="Brand" /></td>
              <td><input onChange={onNewRecordBrandSubChange} type="text" placeholder="Sub Brand" /></td>
            </tr>
          </tbody>
        </table>
        <span
          style={{ display: newRecodAlreadyExists.trim().length > 0 ? 'block' : 'none' }}
          className="AlreadyExists">
          {newRecodAlreadyExists}
        </span>
      </div>
      <ModalActions className="ParentBrandPopupButtons">
        <ModalAction onClick={confirmChange} description="Confirm change" />
        <ModalAction onClick={onClose} description="Cancel" />
      </ModalActions>
    </ModalDialog>
  );

};

export default BrandActionNotValidatedRecordDialog;