import React, { useState } from 'react';
import DashboardPopUp from '../../dashboard-components/DashboardPopUp';
import ReclassifyDropdownsModal from './ReclassifyDropdownsModal';

const ErrorMessage = ({ message }) => {
  return message?.trim().length > 0 && (
    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px', marginTop: '5px' }}>
      {message}
    </span>
  );
};

const ReclassifyModal = ({ productsToBeUpdated, refreshCallback }) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <DashboardPopUp processing={loading} header={"Reclassify product"}>
      <ReclassifyDropdownsModal
        refreshCallback={refreshCallback}
        productsToBeUpdated={productsToBeUpdated}
        setErrorMessage={setErrorMessage}
        setLoading={setLoading}>
        <ErrorMessage message={errorMessage} />
      </ReclassifyDropdownsModal>
    </DashboardPopUp>
  );
};

export default ReclassifyModal;