import React from "react";
import DashboardContainer from "../../dashboard-components/DashboardContainer";
import YouTube from "react-youtube";
import { useFormFieldUtils } from "../../utils/FormHooks";
import { useState } from "react";
import { investorExpressionInterest } from "../../api/SignUp";
import { HTTP_STATUS_CODES } from "../../api/API";
import ModalImage from "react-modal-image";
import "./HowYouShopMatters.scss";

const HowYouShopMatters = () => {
  const firstNameInput = useFormFieldUtils("");
  const emailInput = useFormFieldUtils("");
  const amountInput = useFormFieldUtils("");
  const questions = useFormFieldUtils("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [showThanksMsg, setShowThanksMsg] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();
    setSubmissionError(false);
    setIsSubmitting(false);
    setShowThanksMsg(false);

    const fields = [firstNameInput, emailInput, amountInput, questions];

    const checkIsValid = (value) => value?.trim().length > 0;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let isValid = checkIsValid(field.value);

      field.setIsFieldValid(true);
      isValid = field.checkIsValid(checkIsValid);

      if (!isValid) return;
    }
    callInvestorExpressionInterest();
  };

  const callInvestorExpressionInterest = async () => {
    setIsSubmitting(true);
    const res = await investorExpressionInterest(
      firstNameInput.value,
      emailInput.value,
      amountInput.value,
      questions.value
    );
    setIsSubmitting(false);
    if (res.status != HTTP_STATUS_CODES.NO_CONTENT) {
      setSubmissionError(true);
      return;
    }
    setShowThanksMsg(true);
  };

  return (
    <DashboardContainer
      showMenu={false}
      title={"How You Shop Matters Magazineption"}
    >
      <div className="iei-container">
        <center>
          <div className="overview">
            <table
              className="tablestyle"
              style={{
                width: "100%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td className="cellstyle">
                  <center>
                    <img src="/BBA-Logo-BlW-S.jpg" />
                  </center>
                </td>
              </tr>
            </table>
          </div>

          <div className="title">
            <h1>Why Bring Back Australia?</h1>
            <br />
          </div>

          <div className="overview">
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td
                  className="cellstyle"
                  style={{
                    width: "70%",
                    padding: "5",
                    alignSelf: "center",
                    verticalAlign: "top",
                  }}
                >
                  <p style={{ fontSize: "20px" }}>
                    <b>
                      <i>
                        We are all about <b>Australian Owned</b>!
                      </i>
                    </b>
                  </p>
                  <br />
                  For too long, there has been a focus on Australian Made
                  products, with less emphasis on Australian Owned products, the
                  consequence is that ownership in the majority of iconic
                  Australian brands have been snapped up by foreign interests.
                  <br />
                  <br />
                  For too long, there has been a focus on Australian Made
                  products, with less emphasis on Australian Owned products, the
                  consequence is that ownership in the majority of iconic
                  Australian brands have been snapped up by foreign interests.
                  <br />
                  <br />
                  At Bring Back Australia, our Mission is:
                  <br />
                  <br />
                  <p style={{ fontSize: "24px", color: "#005F40" }}>
                    <center>
                      <b>
                        <i>To Make Australian Owned "The Thing".</i>
                      </b>
                    </center>
                  </p>
                </td>
                <td
                  className="cellstyle"
                  style={{ width: "30%", verticalAlign: "center" }}
                >
                  <center>
                    <ModalImage
                      small={"/MissionPage-SmallCapRaise.jpg"}
                      medium={"/MissionPage-SmallCapRaise-NoShadow.jpg"}
                      imageBackgroundColor={"#005F40"}
                      hideDownload={true}
                      hideZoom={true}
                    />
                    <b>Our Mission</b>
                  </center>
                </td>
              </tr>
            </table>
          </div>

          <div className="heading">
            <h2>What Will Bring Back Australia Do?</h2>
            <br />
          </div>

          <div className="video">
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td
                  className="cellstyle"
                  style={{
                    width: "50%",
                    padding: "5",
                    alignSelf: "center",
                    verticalAlign: "center",
                  }}
                >
                  <YouTube videoId={"2Ck6INYEf_4"} />
                </td>
                <td
                  className="cellstyle"
                  style={{ width: "50%", verticalAlign: "center" }}
                >
                  <center>
                    To watch a short overview of
                    <br />
                    Bring Back Australia, click on
                    <br />
                    the YouTube video link.
                    <br />
                    <br />
                    <br />
                    <b>Duration: 2:56</b>
                  </center>
                </td>
              </tr>
              <tr>
                <td className="cellstyle" colSpan="2">
                  <center>
                    <i>
                      To join with us to launch Bring Back Australia, see below
                      for investment
                      <p />
                      opportunities, please provide your contact details below.
                    </i>
                  </center>
                </td>
              </tr>
            </table>
          </div>

          <div className="overview"></div>

          <div className="heading">
            <h2>Investment Opportunity</h2>
            <br />
          </div>

          <div className="overview">
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td
                  className="cellstyle"
                  style={{
                    width: "70%",
                    padding: "5",
                    alignSelf: "center",
                    verticalAlign: "top",
                  }}
                >
                  Would you like to be a{" "}
                  <b>
                    <i>Founding Shareholder</i>
                  </b>{" "}
                  in Bring Back Australia?
                  <br />
                  <br />
                  There are two upcoming opportunities to invest in{" "}
                  <i>Bring Back Australia</i> and be part of the movement to
                  support, promote and grow Australian Owned Businesses.
                  <br />
                  <br />
                  <b>Open Now</b>
                  <br />
                  Currently the Small Cap Raise is open, this will fund an
                  additional five employees to release our app, the Minimum
                  Viable Product (MVP) to test all of our systems prior to the
                  official release of the Bring Back Australia App in early 2022.
                  The minimum investment for the Small-Cap Raise is $10,000.
                  <br />
                  <br />
                  <b>Early 2022</b>
                  <br />
                  In early 2022 there will be another raise on the Birchal
                  platform. Birchal is an Equity Crowd-funding platform for
                  businesses that are raising funds, this raise will have a
                  minimum Investment of $250 that will enable smaller investors
                  to participate.
                  <br />
                  <br />
                </td>
                <td
                  className="cellstyle"
                  style={{ width: "30%", verticalAlign: "center" }}
                >
                  <center>
                    <ModalImage
                      small={"/Cover-SmallCapRaise.jpg"}
                      medium={"/Cover-SmallCapRaise-NoShadow.jpg"}
                      imageBackgroundColor={"#005F40"}
                      hideDownload={true}
                      hideZoom={true}
                    />
                    <b>Small Cap Raise</b>
                  </center>
                </td>
              </tr>
            </table>
          </div>

          <div className="heading">
            <h2>Why Invest In Bring Back Australia?</h2>
            <br />
          </div>

          <div className="overview">
            <table
              className="tablestyle"
              style={{ width: "90%", verticalAlign: "top" }}
            >
              <tr>
                <td
                  className="cellstyle"
                  style={{ width: "30%", verticalAlign: "center" }}
                >
                  <center>
                    <ModalImage
                      small={"/BackPage-SmallCapRaise.jpg"}
                      medium={"/BackPage-SmallCapRaise-NoShadow.jpg"}
                      imageBackgroundColor={"#005F40"}
                      hideDownload={true}
                      hideZoom={true}
                    />
                    <b>Small Cap Raise</b>
                  </center>
                </td>
                <td
                  className="cellstyle"
                  style={{
                    width: "70%",
                    alignSelf: "center",
                    verticalAlign: "top",
                  }}
                >
                  Being a shareholder and part of the movement in raising
                  awareness of Australian Owned businesses and Australian Made
                  products is not an everyday opportunity. We welcome like
                  minded investors onboard to help us share the message and
                  deliver the solution.
                  <br />
                  <br />
                  A strong Australian economy, underpinned by more Australian
                  Owned businesses with their hearts in Australia, providing
                  secure jobs and paying 100% of their taxes in Australia, is
                  both desirable and achievable.
                  <br />
                  <br />
                  As consumers, we can all play our role in helping to create a
                  strong, sustainable economy with improved living standards for
                  both existing and future generations of Australian families.
                  <br />
                  <br />
                  In part, that role involves doing more to support Australian
                  Owned businesses.
                </td>
              </tr>
            </table>
          </div>

          <div className="heading">
            <h2>Investment Options</h2>
            <br />
          </div>

          <div className="overview">
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr className="rowstyle">
                <td
                  className="cellstyle"
                  style={{ width: "75%", verticalAlign: "top" }}
                >
                  <h3>Small Cap Raise</h3>
                  In preparation for our upcoming Equity Crowd-funding, we will
                  be raising funds via a small-cap raise.
                  <br />
                  <br />
                  This phase is for the early adopters that share our vision.
                  <br />
                  <br />
                  As recognition for your belief in what Bring Back Australia is
                  creating, investors at this point will be rewarded with a 20%
                  premium on their investment.
                </td>
                <td
                  className="cellstyle"
                  style={{ width: "25%", verticalAlign: "top" }}
                >
                  <center>
                    <img src="/Cover-SmallCapRaise.jpg" width={200} />
                    <br />
                    <br />
                    <b>
                      <i>Minimum Investment: $10,000</i>
                    </b>
                  </center>
                </td>
              </tr>
            </table>
          </div>

          <div className="overview">
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td
                  className="cellstyle"
                  style={{ width: "25%", verticalAlign: "top" }}
                >
                  <center>
                    <img src="/Icon-Birchal-Purple.png" width={175} />
                    <br />
                    <br />
                    <b>
                      <i>Minimum Investment: $250</i>
                    </b>
                  </center>
                </td>
                <td
                  className="cellstyle"
                  style={{ width: "75%", verticalAlign: "top" }}
                >
                  <h3>Equity Crowd-funding</h3>
                  To coincide with the official launch of the Bring Back Australia
                  app, we will be raising funds via the Birchal Equity
                  Crowd-funding platform.
                  <br />
                  <br />
                  This will be the major funding stage that will launch Bring Back
                  Australia.
                  <br />
                  <br />
                  This phase enables a broader range of investor to join our
                  movement, to participate and support the project and finally
                  to benefit from what will be created.
                </td>
              </tr>
            </table>

            <br />

            <table
              className="tablestyle"
              style={{
                width: "80%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td
                  className="cellstyle"
                  colspan="2"
                  style={{ lineHeight: 1, verticalAlign: "top" }}
                >
                  <h4>What is Birchal?</h4>
                  <small>
                    In 2018, Birchal became one of the first licensed Equity
                    crowdfunding platforms to allow everyday Australians to buy
                    shares in private and unlisted public companies ranging from
                    consumer brands, tech companies, and more.
                    <br />
                    <br />
                    Based on the experience and knowledge of crowdfunding, they
                    created a platform to make the process of investment simple
                    and fast. All companies listed are open for investment and
                    are inviting others involved in their growth.
                    <br />
                    <br />
                    Today, Birchal is the largest investors' community with more
                    than $65M raised through Equity Crowd-funding for over 100
                    startups and small businesses.
                    <br />
                    <br />
                  </small>
                </td>
                <td>
                  <center>
                    <ModalImage
                      small={"/Image-Birchal.jpeg"}
                      medium={"/Image-Birchal.jpeg"}
                      imageBackgroundColor={"#005F40"}
                      hideDownload={true}
                      hideZoom={true}
                    />
                  </center>
                </td>
              </tr>
            </table>
          </div>

          <div className="heading">
            <br />
            <h2>Registration of Interest</h2>
            <br />
          </div>

          <div className="overview">
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td
                  className="cellstyle"
                  style={{ verticalAlign: "top" }}
                  colSpan="2"
                >
                  <center>
                    <i>
                      <small>
                        Please note, this is a{" "}
                        <b>"registration of interest only"</b>, once you have
                        provided your details, we will send you the offer
                        document outlining the investment for you to review.
                      </small>
                    </i>
                  </center>
                </td>
              </tr>
              <tr>
                <td className="cellstyle" style={{ verticalAlign: "top" }}>
                  Bring Back Australia welcomes you to consider joining us in
                  the mission to{" "}
                  <b>
                    Make <i>Australian Owned</i> "the thing"
                  </b>
                  .<br />
                  <br />
                  To receive the offer document and details of upcoming investor
                  opportunities, please provide your contact details.
                  <br />
                  <br />
                  <h4>Expression of Interest</h4>
                  By expressing your interest, you are making a non-binding
                  indication of your interest to participate in Bring Back
                  Australia's Small Cap Raise. You are also consenting to the
                  use of your personal information to be contacted regarding Buy
                  Back Australia.
                  <br />
                  Submitting an expression of interest is not an investment, and
                  does not create any obligations or rights.
                  <br />
                  <br />
                  To proceed with the Offer, you should consider the Offer
                  document that you will receive before deciding whether to
                  invest.
                  <br />
                  <br />
                  <br />
                  <i>
                    <small>
                      * Bring Back Australia will not sell or share your contact
                      details.
                    </small>
                  </i>
                </td>
                <td
                  className="cellstyle"
                  style={{ width: "60%", verticalAlign: "top" }}
                >
                  {!showThanksMsg && (
                    <form
                      onSubmit={submitForm}
                      className="form"
                      style={{ margin: "0 auto", width: "90%" }}
                    >
                      <table
                        className="tablestyle"
                        style={{
                          width: "100%",
                          alignSelf: "center",
                          verticalAlign: "top",
                        }}
                      >
                        <tr>
                          <td
                            className="cellstyle"
                            style={{ textAlign: "right", verticalAlign: "top" }}
                          >
                            <label>
                              <b>Name</b>
                            </label>
                          </td>
                          <td className="cellstyle">
                            <input
                              ref={firstNameInput.ref}
                              className={
                                firstNameInput.isValid ? "" : "required"
                              }
                              onChange={firstNameInput.onChange}
                              value={firstNameInput.value}
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="cellstyle"
                            style={{ textAlign: "right", verticalAlign: "top" }}
                          >
                            <label>
                              <b>Email</b>
                            </label>
                          </td>
                          <td className="cellstyle">
                            <input
                              ref={emailInput.ref}
                              className={emailInput.isValid ? "" : "required"}
                              onChange={emailInput.onChange}
                              value={emailInput.value}
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="cellstyle"
                            style={{ textAlign: "right", verticalAlign: "top" }}
                          >
                            <label>
                              <b>Amount</b>
                            </label>
                          </td>
                          <td className="cellstyle">
                            <input
                              ref={amountInput.ref}
                              className={amountInput.isValid ? "" : "required"}
                              onChange={amountInput.onChange}
                              value={amountInput.value}
                              type="text"
                              maxLength={15}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="cellstyle"
                            style={{ textAlign: "right", verticalAlign: "top" }}
                          >
                            <label>
                              <b>Questions</b>
                            </label>
                          </td>
                          <td className="cellstyle">
                            <textarea
                              ref={questions.ref}
                              className={questions.isValid ? "" : "required"}
                              onChange={questions.onChange}
                              value={questions.value}
                              rows={10}
                            />
                          </td>
                        </tr>
                        {submissionError && (
                          <tr>
                            <td
                              colSpan="2"
                              style={{
                                fontWeight: "bold",
                                fontStyle: "italic",
                                textAlign: "center",
                                color: "red",
                              }}
                            >
                              Something went wrong, please contact our support!
                            </td>
                          </tr>
                        )}
                        {isSubmitting && (
                          <tr>
                            <td
                              colSpan="2"
                              style={{
                                fontWeight: "bold",
                                fontStyle: "italic",
                                textAlign: "center",
                              }}
                            >
                              We are processing your request ...
                            </td>
                          </tr>
                        )}
                        {!showThanksMsg && !isSubmitting && (
                          <tr>
                            <td colSpan="2" className="action-footer right">
                              <button onClick={submitForm}>Submit</button>
                              <br />
                            </td>
                          </tr>
                        )}
                      </table>
                    </form>
                  )}
                  {showThanksMsg && (
                    <div
                      style={{
                        fontWeight: "bold",
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    >
                      Thanks for registering your interest. We will contact you
                      within 1 business day with your requested information.
                    </div>
                  )}
                </td>
              </tr>
            </table>
          </div>

          <div className="overview">
            <br />
            <br />
            <img src="/BBA-Tagline-Green.png" height={100} />
            <br />
          </div>

          <div className="overview">
            <br />
            <table
              className="tablestyle"
              style={{
                width: "90%",
                alignSelf: "center",
                verticalAlign: "top",
              }}
            >
              <tr>
                <td className="cellstyle" style={{ verticalAlign: "top" }}>
                  <center>
                    <i>
                      <small>
                        * The information on this website is for information
                        purposes only, and should not be considered as a
                        recommendation to invest.
                      </small>
                    </i>
                  </center>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </div>
    </DashboardContainer>
  );
};

export default HowYouShopMatters;
