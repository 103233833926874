import React from 'react';
import { Link } from 'react-router-dom';
import SideMenuGPC from './SideMenuGPC';
import SideMenuItem from './SideMenuItem';

const SideMenuSupplier = () => {

  return (
    <>
      <SideMenuItem>
        <Link to="/">Supplier</Link>
        <SideMenuItem>
          <Link to="/">Home</Link>
          <Link to="/">Brands</Link>
          <Link to="/">Prefixes</Link>
          <Link to="/">Analytics</Link>
        </SideMenuItem>
      </SideMenuItem>
      <SideMenuGPC />
    </>
  );
};

export default SideMenuSupplier;