import React, { useState } from 'react';
import DashboardHeader from './DashboardHeader';
import DashboardBody from './DashboardBody';
import { DashboardPopUpContext } from './context/DashboardPopUpContext';

import './Dashboard.scss';

// import "@fontsource/roboto/100.css";

const DashboardContainer = ({ title, children, showMenu = true }) => {

  const [popUpComponent, setPopUpComponent] = useState(null);

  const showPopUp = (popUpComponent) => setPopUpComponent(popUpComponent);

  const hidePopUp = () => setPopUpComponent(null);

  const isPopUpVisible = () => popUpComponent != null

  return (
    <DashboardPopUpContext.Provider value={{ isPopUpVisible, showPopUp, hidePopUp }}>
      <div className="container">
        <DashboardHeader title={title}>

        </DashboardHeader>

        <DashboardBody>
          {children}
        </DashboardBody>
      </div>
      {popUpComponent}
    </DashboardPopUpContext.Provider>
  );
};

export default DashboardContainer;