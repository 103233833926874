import React, { useContext, useEffect, useState } from 'react';
import { assignGVDToWordMatch, getGroups, getGroupVarDetails, getGroupVars } from '../../../api/GroupVariantDetail';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import { DataTableSelectedRowsContext } from '../../components/DataTableSelectedRowsContext';
import { SelectedBrickContext } from './SelectedBrickContext';
import UnassignedWordsGVDNewForm from './UnassignedWordsGVDNewForm';

const UnassignedWordsGVDForm = () => {

  const { selectedBrick, refreshBrick } = useContext(SelectedBrickContext);
  const { selectedRows, resetSelectedRows } = useContext(DataTableSelectedRowsContext);

  const [showNewGVDForm, setShowNewGVDForm] = useState(false);

  const [groupOptions, setGroupOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);
  const [detailOptions, setDetailOptions] = useState([]);

  const [selectedGroup, setSelectedGroup, onSelectedGroupChange] = useFormFieldOnChange('');
  const [selectedVariant, setSelectedVariant, onSelectedVariantChange] = useFormFieldOnChange('');
  const [selectedDetail, setSelectedDetail, onSelectedDetailChange] = useFormFieldOnChange('');

  const toggleNewGVDForm = () => setShowNewGVDForm(!showNewGVDForm);

  const fetchGroups = async () => {
    if (selectedBrick.trim().length == 0) return;
    const groups = await getGroups(selectedBrick);
    if (groups !== false) {
      setGroupOptions(groups.data);
    }
    if (groups.data.length > 0) {
      setSelectedGroup('');
      setSelectedVariant('');
      setSelectedDetail('');
      setSelectedGroup(groups.data[0].Group);
    }
  };

  const fetchVariants = async (group) => {
    const variants = await getGroupVars(selectedBrick, group);
    if (variants !== false) {
      setVariantOptions(variants.data);
    }
    if (variants.data.length > 0) {
      setSelectedVariant(variants.data[0].Variant);
    }
  };

  const fetchDetails = async (group, variant) => {
    const details = await getGroupVarDetails(selectedBrick, group, variant);
    if (details !== false) {
      setDetailOptions(details.data);

      if (details.data.length > 0) {
        setSelectedDetail(details.data[0].Detail);
      }
    }
  };

  const onOperationFinishes = () => {
    resetSelectedRows([]);
    setShowNewGVDForm(false);
    refreshBrick();
  };

  const onCancelClick = () => resetSelectedRows([]);

  const onAssignClick = async () => {
    const reqRes = await assignGVDToWordMatch(selectedBrick, selectedGroup, selectedVariant, selectedDetail, selectedRows.map(wordMatch => wordMatch.WordMatchID));
    if (reqRes !== false) {
      onOperationFinishes();
    }
  };

  useEffect(() => {
    selectedBrick?.trim().length && fetchGroups();
  }, [selectedBrick]);

  useEffect(() => {
    (selectedGroup == null || selectedGroup?.trim().length) &&
      fetchVariants(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    (selectedGroup == null || selectedGroup?.trim().length) &&
      (selectedVariant == null || selectedVariant?.trim().length) &&
      fetchDetails(selectedGroup, selectedVariant);
  }, [selectedVariant]);

  if (showNewGVDForm) {
    return (
      <UnassignedWordsGVDNewForm onOperationFinishes={onOperationFinishes} />
    );
  }

  return (
    <>
      <label htmlFor="groups">Group: </label>
      <select id="groups" value={selectedGroup ?? ''} onChange={onSelectedGroupChange}>
        {groupOptions.map((group, index) => (
          <option key={index} value={group.Group}>{group.Group}</option>
        ))}
      </select>

    &nbsp;<label htmlFor="variants">Variants: </label>
      <select id="variants" value={selectedVariant ?? ''} onChange={onSelectedVariantChange}>
        {variantOptions.map((variant, index) => (
          <option key={index} value={variant.Variant}>{variant.Variant ? variant.Variant : ' - NULL -'}</option>
        ))}
      </select>

    &nbsp;<label htmlFor="details">Detail: </label>
      <select id="details" value={selectedDetail ?? ''} onChange={onSelectedDetailChange}>
        {detailOptions.map((detail, index) => (
          <option key={index} value={detail.Detail}>{detail.Detail ? detail.Detail : ' - NULL -'}</option>
        ))}
      </select>

    &nbsp; <button onClick={onAssignClick}>Assign</button>
    &nbsp; <button onClick={onCancelClick}>Cancel</button>
    &nbsp; <button onClick={toggleNewGVDForm}><b>New</b></button>
    </>
  );
};

export default UnassignedWordsGVDForm;