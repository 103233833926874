import React from 'react';

const DashboardBody = ({children}) => {

  return (
    <div className="body">
      {children}
    </div>
  );
};

export default DashboardBody;