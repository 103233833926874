import React, { useContext, useState } from 'react';
import GVDItem from './GVDItem';
import GVDItemVariant from './GVDItemVariant';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { GroupModel, GVDEditorAPI, VariantModel } from '../GVDEditorAPI';
import { HTTP_STATUS_CODES } from '../../../api/API';

type GVDItemGroupProps = {
  group: GroupModel
}

const GVDItemGroup = ({ group }: GVDItemGroupProps) => {

  const { BRICK, refreshBrick } = useContext(ContextSelectedBrick);

  const [variants, setVariants] = useState<VariantModel[]>([]);

  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const fetchData = async () => {
    const res = await GVDEditorAPI.getGroupVars(BRICK, group.GroupingID);

    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    group.Total = res.data.length;
    setVariants(res.data);
  };

  const onAddNew = async (description: string) => {
    const res = await GVDEditorAPI.newVariant(description, group.GroupingID);
    if (!res || res.status != HTTP_STATUS_CODES.CREATED) {
      return;
    }
    fetchData();
  };

  const onRename = async (description: string) => {
    const res = await GVDEditorAPI.renameGroup(group.GroupingID, description);
    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    group.GroupingDesc = description;
  };

  const onDelete = async () => {
    const res = await GVDEditorAPI.deleteGroup(group.GroupingID);
    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    setHasBeenDeleted(true);
  };

  return hasBeenDeleted ? null : (
    <GVDItem
      description={group.GroupingDesc}
      fetchSubItems={fetchData}
      onAddNew={onAddNew}
      onRename={onRename}
      onDelete={group.Total > 0 ? false : onDelete}>

      {variants.map((variant, key) => (
        <GVDItemVariant key={key} group={group} variant={variant} />
      ))}

    </GVDItem>
  );
};

export default GVDItemGroup;