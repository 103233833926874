import React, { useContext } from 'react';
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import { BarcodeSearchButton } from './ProductScanNewStyle';
import ReclassifyModal from './ReclassifyModal';

const ButtonShowReclassifyModal = (props) => {

  const { showPopUp } = useContext(DashboardPopUpContext);

  const showCompromisedPopUp = () => {
    showPopUp(<ReclassifyModal {...props} />);
  }

  return (
    <BarcodeSearchButton onClick={showCompromisedPopUp}>Classify</BarcodeSearchButton>
  );
};

export default ButtonShowReclassifyModal;