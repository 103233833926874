import React, { useContext, useEffect, useState } from 'react'
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { assignGVDToWordMatch, getGroups, getGroupVarDetails, getGroupVars, newGVD } from '../../../../api/GroupVariantDetail';
import { useFormFieldOnChange } from '../../../../utils/FormHooks';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import { SelectedRawWordsContext } from '../context/SelectedRawWordsContext';

const AssignCreateGVDContainer = () => {

  const [selectedGroup, setSelectedGroup, onSelectedGroupChange] = useFormFieldOnChange('');
  const [selectedVariant, setSelectedVariant, onSelectedVariantChange] = useFormFieldOnChange('');
  const [selectedDetail, setSelectedDetail, onSelectedDetailChange] = useFormFieldOnChange('');

  const gvdFieldsCreation = {
    Group: {
      field: useFormFieldOnChange(''),
      visible: useState(false),
      onNewShow: ['Group', 'Variant', 'Detail']
    },
    Variant: {
      field: useFormFieldOnChange(''),
      visible: useState(false),
      onNewShow: ['Variant', 'Detail']
    },
    Detail: {
      field: useFormFieldOnChange(''),
      visible: useState(false),
      onNewShow: ['Detail']
    }
  };

  const [groups, setGroups] = useState([]);
  const [variants, setVariants] = useState([]);
  const [details, setDetails] = useState([]);

  const { BRICK, refreshBrick } = useContext(ContextSelectedBrick);

  const { selectedWords, clearWords } = useContext(SelectedRawWordsContext);

  const { refreshGVDs } = useContext(SelectedGVDDetailContext);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    (BRICK?.trim().length ?? 0) > 0 && loadGVDOptions();
  }, [BRICK]);

  useEffect(() => {
    (selectedGroup?.trim().length ?? 0) > 0 && fetchVariants();
  }, [selectedGroup]);

  useEffect(() => {
    (selectedVariant?.trim().length ?? 0) && fetchDetails();
  }, [selectedVariant]);

  const loadGVDOptions = () => {
    cancel();

    setGroups([]);
    setVariants([]);
    setDetails([]);

    fetchGroups();
    refreshGVDs();
  };

  const fetchGroups = async () => {
    const data = await callApi(async () => await getGroups(BRICK));

    setGroups(data);

    setSelectedGroup(data.length > 0 ? new String(data[0].GroupingID) : null);
  };

  const fetchVariants = async () => {
    const data = await callApi(async () => await getGroupVars(BRICK, selectedGroup));

    setVariants(data);

    setSelectedVariant(data.length > 0 ? new String(data[0].VariantID) : null);
  };

  const fetchDetails = async () => {
    const data = await callApi(async () => await getGroupVarDetails(BRICK, selectedGroup, selectedVariant));

    setDetails(data);

    setSelectedDetail(data.length > 0 ? data[0].DetailID : null);
  };

  const callApi = async (fnCallApi) => {
    const res = await fnCallApi();

    return res.status != HTTP_STATUS_CODES.OK ? [] : res.data;
  };

  const setGvdFieldsVisibility = (fields, visible) => {
    fields.forEach(field => {
      gvdFieldsCreation[field].visible[1](visible);

      if (!visible) {
        gvdFieldsCreation[field].field[1]('');
      }
    });
  };

  const cancel = () => {
    setGvdFieldsVisibility(['Group', 'Variant', 'Detail'], false)
    clearWords();
  };

  let hasCreatedNewGVD = false;
  const assign = async () => {
    if ((BRICK?.trim().length ?? 0) == 0) return;

    setProcessing(true);

    const gvd = await getGVDToAssignTo();

    if (gvd == false || selectedWords.length == 0) {

      if (hasCreatedNewGVD) {
        loadGVDOptions();
      }

      setProcessing(false);
      return;
    };

    const [group, variant, detail] = gvd;

    const res = await assignGVDToWordMatch(
      BRICK, group, variant, detail, selectedWords.map(wordMatch => wordMatch.WordMatchID)
    );

    setProcessing(false);

    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    loadGVDOptions();
    refreshBrick()
  };

  const getGVDToAssignTo = async () => {
    let group = selectedGroup;
    let variant = selectedVariant;
    let detail = selectedDetail;

    let createNewGVD = false;
    if (gvdFieldsCreation.Group.visible[0] == true) {
      group = gvdFieldsCreation.Group.field[0];
      createNewGVD = true;
    }

    if (gvdFieldsCreation.Variant.visible[0] == true) {
      variant = gvdFieldsCreation.Variant.field[0];
      createNewGVD = true;
    }

    if (gvdFieldsCreation.Detail.visible[0] == true) {
      detail = gvdFieldsCreation.Detail.field[0];
      createNewGVD = true;
    }

    if (createNewGVD) {
      const res = await newGVD(BRICK, group, variant, detail);

      if (res.status != HTTP_STATUS_CODES.OK) {
        return false;
      }

      hasCreatedNewGVD = true;
    }

    return [group, variant, detail];
  };

  const getDropdown = (label, data, key, desc, dropdownValue, onSelectedOnChange) => {
    const fieldCreation = gvdFieldsCreation[label];

    const createNewFn = () => setGvdFieldsVisibility(fieldCreation.onNewShow, true);

    return (
      <>
        <label className="gvd-label">{label}</label>

        {!fieldCreation.visible[0] && (
          <>
            <select className="gvd-dropdown" value={dropdownValue ?? ''} onChange={onSelectedOnChange}>
              {data.map((item, index) => (
                <option key={index} value={item[key]}>{item[desc] ?? 'Not Defined'}</option>
              ))}
            </select>
            <button className="gvd-new-btn" onClick={createNewFn}>+</button>
          </>
        )}

        {fieldCreation.visible[0] && (
          <>
            <input value={fieldCreation.field[0]} onChange={fieldCreation.field[2]} className="brick-input" type="text" />
            <span></span>
          </>
        )}

      </>
    );
  };

  return (
    <>
      <div className="gvd-assign-create request-processing-indicator">
        <div className="container-body">

          <div className="gvd-selection-container">
            {getDropdown('Group', groups, 'GroupingID', 'GroupingDesc', selectedGroup, onSelectedGroupChange)}

            {getDropdown('Variant', variants, 'VariantID', 'VariantDesc', selectedVariant, onSelectedVariantChange)}

            {getDropdown('Detail', details, 'DetailID', 'DetailDesc', selectedDetail, onSelectedDetailChange)}
          </div>
          <div className="gvd-assign-cancel-container">
            <button className="gvd-assign-btn" onClick={assign}>Assign</button>
            <button className="gvd-cancel-btn" onClick={cancel}>Cancel</button>
          </div>

        </div>

        {processing && (
          <div className="request-processing">
            <span>Processing...</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AssignCreateGVDContainer;