import React, { useContext, useState } from 'react';
import { HTTP_STATUS_CODES } from '../../../api/API';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { DetailModel, GroupModel, GVDEditorAPI, VariantModel } from '../GVDEditorAPI';
import GVDItem from './GVDItem';

type GVDItemDetailProps = {
  group: GroupModel,
  variant: VariantModel,
  detail: DetailModel
}

const GVDItemDetail = ({ group, variant, detail }: GVDItemDetailProps) => {

  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const { refreshBrick } = useContext(ContextSelectedBrick);

  const onRename = async (description: string) => {
    const res = await GVDEditorAPI.renameDetail(detail.DetailID, description);
    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    detail.DetailDesc = description;
  };

  const onDelete = async () => {
    const res = await GVDEditorAPI.deleteDetail(detail.DetailID);
    if (!res || res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    setHasBeenDeleted(true);
  };

  return hasBeenDeleted ? null : (
    <GVDItem
      description={detail.DetailDesc}
      onAddNew={false}
      onRename={onRename}
      onDelete={onDelete} />
  );
};

export default GVDItemDetail;