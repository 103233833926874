import * as React from 'react';
import DashboardContainer from '../../../dashboard-components/DashboardContainer';
import BrickSearchContainer from '../components/BrickSearchContainer';
import GVDTreeContainer from './GVDTreeContainer';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';

const GVDEditor = () => {

  const [brick, setBrick] = React.useState('');
  const [brickDesc, setBrickDesc] = React.useState('');

  const refreshBrick = () => {
    const copyBrick = brick;
    setBrick('');
    setBrick(copyBrick);
  };

  const setSelectedBrick = (newBrick: string | null) => {
    setBrick(newBrick || '');
  };

  const setSelectedBrickDesc = (newDesc: string | null) => {
    setBrickDesc(newDesc || '');
  };

  return (
    <DashboardContainer title={"GVD Editor"}>
      <ContextSelectedBrick.Provider value={{
        BRICK: brick,
        BrickDesc: brickDesc,
        refreshBrick, setSelectedBrick, setSelectedBrickDesc
      }}>

        <BrickSearchContainer />
        <GVDTreeContainer />

      </ContextSelectedBrick.Provider>
    </DashboardContainer>
  );
};

export default GVDEditor;